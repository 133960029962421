import React, { useEffect, useRef } from 'react';
import ReceiptTop from '../../components/dashboards/ReceiptTop';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import formatDate from '../../components/dashboards/FormatData';
import getTime from '../../components/dashboards/GetTime';
import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Receipt = () => {


  const dispatch = useDispatch();
  const { receipts } = useSelector((state) => state.history);
  const receiptRef = useRef(null);
  const componentRef = useRef();

  // const handleDownload = () => {
  //   const input = receiptRef.current; // Get the receipt content
    
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

  //       pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //       pdf.save('payeelord transaction receipt.pdf'); // Download as receipt.pdf
  //     })
  //     .catch((error) => {
  //       console.error('Error generating PDF:', error);
  //     });
  // };
  const handleDownload = () => {
    const input = receiptRef.current; // Get the receipt content
    
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `payeelord_${receipts?.trans_name}_receipt.png`;
        link.click();
      })
      .catch((error) => {
      });
  };
  const handlePrint = () => {
    if (componentRef.current) {
      componentRef.current.handlePrint();
    }
  };

  return (
    <div className='flex bg-[#FFF3ED] flex-col px-[24px] justify-center md:justify-between xl:px-[74px] gap-4 rounded-lg lg:gap-28 my-[24px] md:my-10'>
      <ReceiptTop />
      <div className='flex flex-col px-8 mt-24 md:mt-4 md:flex-row items-start md:items-center bg-white w-full my-3 gap-4 justify-between pt-3'>
        <div ref={receiptRef} className='py-[81px] rounded-lg mt-10 md:w-2/3'>
          <p>Transaction Details</p>
          <table className="w-full border-[1px] border-solid border-black text-sm rounded-xl mt-2">
            <tbody>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Payment Method</td>
                <td className="py-2 px-4">Wallet</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Service</td>
                <td className="py-2 px-4">{receipts?.trans_name}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Phone</td>
                <td className="py-2 px-4">{receipts?.mobile_number}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Transaction ID</td>
                <td className="py-2 px-4">{receipts?.transaction_id}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">API Response</td>
                <td className="py-2 px-4">{receipts?.api_response}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Amount</td>
                <td className="py-2 px-4">₦{receipts?.amount}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Total Amount Payable</td>
                <td className="py-2 px-4">₦{receipts?.amount}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Initial Balance</td>
                <td className="py-2 px-4">₦{receipts?.balance_before}</td>
              </tr>
              <tr className="border-b">
                <td className="py-2 px-4 font-semibold">Final Balance</td>
                <td className="py-2 px-4">₦{receipts?.balance_after}</td>
              </tr>
              {receipts?.address && receipts.address.length > 0 && (
                <tr className="border-b">
                  <td className="py-2 px-4 font-semibold">Address</td>
                  <td className="py-2 px-4">{receipts.address}</td>
                </tr>
              )}
             
              <tr>
                <td className="py-2 px-4 font-semibold">Date</td>
                <td className="py-2 px-4">{formatDate(receipts?.create_date)}, {getTime(receipts?.create_date)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='flex justify-center w-full md:w-auto flex-col gap-4'>
          <Link
            className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex gap-2 items-center justify-center text-[12px] xl:text-[16px] ease-in'
          >
            Perform Another Transaction
          </Link>
          <button
            onClick={handleDownload}
            className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex gap-2 items-center justify-center text-[12px] xl:text-[16px] ease-in'
          >
            Download Receipt
          </button>
          <ReactToPrint
            trigger={() => <button className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex gap-2 items-center justify-center text-[12px] xl:text-[16px] ease-in'
            >Print Receipt</button>}
            content={() => receiptRef.current}
          />
        </div>
      </div>
    </div>
  );
};

export default Receipt;
