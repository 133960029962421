import { useDispatch } from 'react-redux';
import { success_icon } from '../assets';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import {
  clearAirtimeCashMessage,
  clearAirtimeMessage,
  clearDataMessage,
} from '../controller/buySlice';
import { clearBillMessage } from '../controller/buyElectricSlice';
import { clearCableMessage } from '../controller/buyCableSlice';
import {  getUser,  } from '../controller/authSlice';

const SuccessAlert = ({ message, isVisible, onClose, path }) => {
  const dispatch = useDispatch();
  const boxRef = useRef();

  useEffect(() => {
    if (!isVisible) return;

    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, dispatch]);

  const handleClose = async () => {
    dispatch(clearAirtimeMessage());
    dispatch(clearDataMessage());

    dispatch(clearCableMessage());
    dispatch(clearBillMessage());
    dispatch(clearAirtimeCashMessage());

    // dispatch(setUserBalance());
    // showSpiner
    dispatch(getUser());
    // closeSpinner
    if (onClose) onClose();
  };
 
  if (!isVisible) return null;

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
      <div ref={boxRef} className='bg-white p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] md:min-h-[300px] h-[300px] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className='text-[#00A52C] font-semibold'>Successful</p>
          <p className='text-center text-[16px] leading-[21px]'>
            {message}
          </p>
        </div>
        <div className=''>
          <img src={success_icon} alt='Success' />
        </div>
        <Link to={path}>
          <button
            className='py-2 px-10 bg-[#FF7D3B] rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
            onClick={handleClose}
          >
            Okay
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessAlert;
