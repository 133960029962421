import React, { useState } from 'react';
import { quickAccess } from '../constants';
import { Link } from 'react-router-dom';

const QuickAccess = () => {
  const [active, setActive] = useState(false);

  const handleLinkClick = () => {
    setActive(false);
  };

  return (
    <div className='bg-[#FF7D3B] flex  justify-center py-[36px] text-white  h-auto md:flex-col md:h-full w-auto md:w-[135px] rounded-[30px] md:rounded-t-[30px]'>
      <div className='hidden md:flex items-center justify-center w-full text-center pb-[30px]'>
        <h1 className='text-xl font-bold  text-center whitespace-nowrap'>Quick Access</h1>
      </div>
      <div className='flex flex-col   md:flex md:flex-col md:items-center md:justify-center w-full  gap-[10px]'>
        
        <div className='flex items-center px-4  w-full justify-center gap-4 md:flex-col '>

  <div className='flex md:items-center justify-between gap-[10px] md:flex-col w-full '>
  {quickAccess.slice(0, 4).map((item, index) => (
    <div key={index} className='flex items-start md:items-center gap-1 text-center cursor-pointer'>
      <h1 className='text-[18px] font-[700] block md:hidden'>{item.text}</h1>
    <div  className={`flex items-center flex-col gap-1 text-center cursor-pointer ${index === 0 ? 'hidden' : ''}`}>
      

      <Link
        to={item.path}
        className='bg-white w-[53px] h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in'
        onClick={handleLinkClick}
      >
        <img src={item.img} alt='Image'/>
      </Link>
      <p className='text-[14px] lg:text-[14px] whitespace-nowrap'>{item.label}</p>

      </div>
      
          </div>
  ))}
  </div>
</div>

        <div className='flex justify-between md:flex-col px-4 '>
          {quickAccess.slice(4).map((item, index) => (
            <div key={index} className='flex items-center flex-col gap-1 text-center cursor-pointer'>
              <Link
                to={item.path}
                className='bg-white w-[53px] h-[53px] shadow-md flex items-center justify-center rounded-[11px] hover:bg-[#f3f0ee] duration-300 ease-in'
                onClick={handleLinkClick}
              >
                <img src={item.img} alt='Image'/>
              </Link>
              <p className='text-[14px] lg:text-[16px] whitespace-nowrap'>{item.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickAccess;
