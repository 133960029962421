import React from 'react'
import { Link } from 'react-router-dom'

const DeveloperTop = () => {
return (
    <div className='px-5 md:px-20 mt-[100px] py-14' >
            <div className='flex flex-col gap-4 my-5 justify-center items-center'>
                    <h2 className='text-sm xl:text-2xl font-bold'>RESTFUL API for All Platforms</h2>
                    <p>
                    Take advantage of getting affordable digital services to drive more sales and increase your customer base.
                    </p>

                    <div className='flex items-center justify-center gap-6 py-5'>
                            <Link to='/register' className='bg-[#FF7D3B] text-white px-5 py-2 rounded-md hover:opacity-[0.6] duration-300 ease-in hover:bg-[#FF5722]'>Get Started</Link>
                            <a href="https://documenter.getpostman.com/view/28077168/2sAY4siPVW" target='_blank' className='bg-gray-300 text-white px-5 py-2 rounded-md hover:bg-gray-400'>View Docs</a>
                    </div>
            </div>
    </div>
)
}

export default DeveloperTop