import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { addBank } from '../../controller/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EnterBankAccount = ({ image, title, text, btnText }) => {
    const [selectedBank, setSelectedBank] = useState(null);
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const { loading, error, message } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const accountTypes = [
        { id: 1, label: "Savings", value: "savings" },
        { id: 2, label: "Current", value: "current" }
    ];

    const bankNames = [
        { id: 1, label: "Access Bank", value: "Access Bank" },
        { id: 2, label: "United Bank Africa", value: "UBA" },
        { id: 3, label: "Stanbic IBTC", value: "Stanbic IBTC" },
        { id: 4, label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
        { id: 4, label: "Opay", value: "Opay" },
        { id: 4, label: "Palmpay", value: "Palmpay" },
    ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B'
            }
        })
    };

    const handleBankNameChange = (selectedOption) => {
        setSelectedBank(selectedOption);
    };

    const handleAccountTypeChange = (selectedOption) => {
        setSelectedAccountType(selectedOption);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataPayload = {
            account_name: accountName,
            account_number: accountNumber,
            bank: selectedBank?.value,
            account_type: selectedAccountType?.value,
        };

        try {
            const resultAction = await dispatch(addBank(dataPayload));
            if (resultAction.type === addBank.fulfilled.type) {
                toast.success(message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    if (loading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
                <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
                    <div className="loader" />
                </div>
            </div>
        );
    }

    return (
        <div className='py-[24px] w-full flex gap-5 justify-between flex-col md:flex-row items-start'>
            <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto shadow-lg rounded-xl border-solid border-[#E5E5E5]'>
                {image && (
                    <div className='flex items-center justify-center pb-10 w-full gap-2'>
                        <img src={image} alt='Image' />
                        <div className='items-start flex-col'>
                            <h1 className='text-base font-bold'>{title}</h1>
                            <p className='text-gray-400 text-xs'>{text}</p>
                        </div>
                    </div>
                )}
                <form className='flex justify-between gap-5' onSubmit={handleSubmit}>
                    <div className='flex flex-col gap-4 w-full'>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Bank Name</p>
                            <Select
                                options={bankNames.map(bank => ({ label: bank.label, value: bank.value }))}
                                styles={customStyles}
                                classNamePrefix="react-select"
                                className="w-full"
                                onChange={handleBankNameChange}
                                placeholder="Select Bank"
                            />
                        </div>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Account Number</p>
                            <input
                                type="text"
                                required
                                onChange={(e) => setAccountNumber(e.target.value)}
                                placeholder='Enter Account Number'
                                className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500'
                            />
                        </div>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Account Name</p>
                            <input
                                type="text"
                                required
                                onChange={(e) => setAccountName(e.target.value)}
                                placeholder='Enter Account Name'
                                className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500'
                            />
                        </div>
                        <div>
                            <p className='text-xs font-semibold pb-1'>Account Type</p>
                            <Select
                                options={accountTypes.map(acct => ({ label: acct.label, value: acct.value }))}
                                styles={customStyles}
                                classNamePrefix="react-select"
                                className="w-full"
                                onChange={handleAccountTypeChange}
                                placeholder="Select Account Type"
                            />
                        </div>
                        <button
                            type="submit"
                            className='w-auto h-[46px] font-semibold px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'
                        >
                            {btnText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EnterBankAccount;
