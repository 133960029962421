import React from 'react'
import { developers } from '../constants'
import NavBar from '../components/NavBar'
import DeveloperTop from '../components/DeveloperTop'
import { Helmet } from 'react-helmet-async'

const DeveloperPage = () => {
  return (
    <>
    <Helmet>
      <title>PayeeLord Developer API - Build Payment Solutions for MTN, GLO, Airtel, 9Mobile Services</title>
      <meta name="description" content="PayeeLord Developer API for integrating airtime, data, Cable TV, and utility payment solutions. Build secure payment systems for MTN, GLO, Airtel, 9Mobile services." />
      <meta name="keywords" content="payment API, developer tools, MTN integration, GLO integration, Airtel integration, 9Mobile integration, DSTV payment API, GOTV payment API, utility payments API, Nigeria payment solutions" />
      <meta name="payeelordapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      
      <meta itemprop="name" content="PayeeLord Developer API - Complete Payment Integration Solutions" />
      <meta itemprop="description" content="Integrate digital payments for airtime, data bundles, Cable TV subscriptions, electricity bills and more. Build reliable payment solutions with PayeeLord's developer-friendly APIs." />
      <meta itemprop="image" content="https://payeelord.com/images/hero images/hero bg.png" />
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="PayeeLord Developer API - Payment Integration for Digital Services" />
      <meta name="twitter:description" content="Build payment solutions for airtime, data, TV subscriptions and utility bills with PayeeLord's comprehensive API documentation and developer tools." />
      <meta name="twitter:image:src" content="https://payeelord.com/images/hero bg.png" />
      
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="PayeeLord Developer API - Complete Payment Integration Solutions" />
      <meta property="og:description" content="Integrate digital payments for airtime, data bundles, Cable TV subscriptions, electricity bills and more. Build reliable payment solutions with PayeeLord's developer-friendly APIs." />
      <meta property="og:image" content="https://payeelord.com/images/hero bg.png" />
      <meta property="og:site_name" content="PayeeLord" />
      <meta property="og:url" content="https://payeelord.com/developer" />
      
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://payeelord.com/developer" />
    </Helmet>
   
    <div className='w-full px-6 md:px-14'>
<DeveloperTop/>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10 lg:gap-20 p-5 md:p-10 lg:p-20'>
         {
            developers.map((item, index)=>(
                <div className='flex flex-col justify-start gap-10 max-w-min-[300px] px-6 py-8 shadow-lg rounded-md' key={index}>
                    <div className='flex items-center gap-3'>
                        <div className='bg-white p-2 rounded-full text-[#FF7D3B] shadow-md font-extrabold text-2xl'>
                        {item.icon}
                        </div>
                        <h1 className='text-lg font-bold'>{item.label}</h1>

                    </div>
                   <p className='text-sm text-justify'>{item.description}</p>

                </div>
            ))
         }   
   
        </div>
    </div>
    </>
  )
}

export default DeveloperPage