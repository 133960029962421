import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../controller/authSlice';

const useInactivityLogout = () => {
  const dispatch = useDispatch();
  const logoutTime = 600000; // 10 minutes (600,000ms)

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      // Clear existing timeout before setting a new one
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout for logout after inactivity
      timeoutId = setTimeout(() => {
        dispatch(logout());
      }, logoutTime);

    };

    // Event handler for any user activity
    const handleActivity = () => {
      resetTimeout();
    };

    // Add event listeners for mouse, keyboard, and scroll activities
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Initialize the timeout on component mount
    resetTimeout();

    // Cleanup event listeners and timeout on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      clearTimeout(timeoutId);
    };
  }, [dispatch, logoutTime]); // Dependencies remain the same

};

export default useInactivityLogout;
