// actions/transactionActions.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";
// slices/historySlice.js
import { createSlice } from "@reduxjs/toolkit";




export const getTransReceipt = createAsyncThunk(
  'history/getTransReceipt',
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/reciept/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

  

      return response.data.data;
    } catch (error) {

      if (error.response) {
      }

      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

export const getAirtimeTrans = createAsyncThunk(
  'history/getAirtimeTrans',
  async ({ page, limit, search, filter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.get(`/v1/airtime/history`, {
        params: {
          page: page || 1,
          per_page: limit || 10,
          search: search || '',
          filter: filter || '',
        },
        headers: { Authorization: `Bearer ${token}` }
      });


      return response.data;
    } catch (error) {

      if (error.response) {
      }

      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

export const getDataTrans = createAsyncThunk(
  'history/getDataTrans',
  async ({ page, limit, search, filter }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      // const response = await axiosClient.get(`/v1/data/history?page=${page}&per_page=${limit}&search=${search}&filter=${filter}`, {
      //   headers: { Authorization: `Bearer ${token}` }
      // });

      const response = await axiosClient.get(`/v1/history`, {
        params: {
          page: page || 1,
          per_page: limit || 10,
          search: search || '',
          filter: filter || '',
        },
        headers: { Authorization: `Bearer ${token}` }
      });

      return response.data;
    } catch (error) {

      return rejectWithValue(
        error.response?.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

const historySlice = createSlice({
  name: 'history',
  initialState: {
    airtimeTransactions: [],
    dataTransactions: [],
    receipts: [],
    loadingHistory : false,
    error: null,
    
    success: false,
    page: 1,
    limit: 10,
    totalPages: 0,
    search: '',
    filter: ''
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirtimeTrans.pending, (state) => {
        state.loadingHistory  = true;
        state.error = null;
      })
      .addCase(getAirtimeTrans.fulfilled, (state, action) => {
        state.airtimeTransactions = action.payload.transactions;
        state.loadingHistory  = false;
       
        state.success = true;
        state.totalPages = action.payload.meta.last_page;
      })
      .addCase(getAirtimeTrans.rejected, (state, action) => {
        state.loadingHistory  = false;
        state.error = action.payload || action.error.message;
       
        state.success = false;
      })
      .addCase(getDataTrans.pending, (state) => {
        state.loadingHistory  = true;
        state.error = null;
      })
      .addCase(getDataTrans.fulfilled, (state, action) => {
        state.dataTransactions = action.payload.transactions;
        state.loadingHistory = false;
       
        state.totalPages = action.payload.meta.last_page;
      })
      .addCase(getDataTrans.rejected, (state, action) => {
        state.loadingHistory  = false;
        state.error = action.payload || action.error.message;
       
      })
      .addCase(getTransReceipt.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(getTransReceipt.fulfilled, (state, action) => {
        state.receipts = action.payload;
        state.loadingHistory  = false;
       
       
      })
      .addCase(getTransReceipt.rejected, (state, action) => {
        state.loadingHistory  = false;
        state.error = action.payload || action.error.message;
       
      });
  }
});

export const { setPage, setLimit, setSearch, setFilter } = historySlice.actions;
export default historySlice.reducer;
