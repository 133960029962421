
import { FiGrid } from "react-icons/fi";
import { aboutBoy, aboutMan, aboutWoman, airtel_icon, airtime_cash_txt_icon, airtime_icon, airtime_to_cash_icon, appstore, arrow_icon, automated, bulksms_icon, caroline_review, circle, circle_plus_icon, contact_icon, customer_feedback_icon, customercare, dashboard_icon, data_icon, data_txt_icon, developer_api_icon, efficiency_icon, electric_subscription_icon, facebook_icon, glo_icon, happyboy_hero, home_icon, howitwork_line, ima_abasi_review, instagram_icon, juliet_review, linkind_icon, location_icon, logo,  mail_icon, mavroy_review, mayowa_review, mtn_icon, nine_mobile_icon, phone_icon, playstore, reliableImg, service_icon, settings_icon, tv_subscription_icon, wallet_txt_icon, x_icon } from "../assets";
import { FaDownload, FaLaptopCode, FaRegEye} from "react-icons/fa";
import { GiSmartphone, GiTakeMyMoney } from "react-icons/gi";
import { RiLightbulbFlashLine, RiPriceTag2Fill } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
import { MdLaptopChromebook } from "react-icons/md";
import { IoSettingsOutline, IoWifiOutline } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { SiAmazonapigateway } from "react-icons/si";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { MdOutlineSecurity } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { HiBanknotes } from "react-icons/hi2";
import { TbShoppingCartCode } from "react-icons/tb";
import { BsRobot } from "react-icons/bs";

export const navLink = [
    {
        path: '/',
        label: 'Home'
    },
    {
        path: '/services',
        label: 'Our Services'
    },
    {
        path: '/about',
        label: 'About Us'
    },
    {
        path: '/contact',
        label: 'Contact Us'
    },
    {
        path: '/ziri',
        label: 'Ziri(WhatsApp Bot)'
    },
    {
        path: '/developer',
        label: 'Developer API'
    },
];

export const mobileNavLinks = [

{
    img: home_icon,
    label: 'Home',
    path: '/'
},
{
    img: service_icon,
    label: 'Our Services',
    path: 'services'
},
{
    img: contact_icon,
    label: 'Contact Us',
    path: 'Contact'
},
{
    img: dashboard_icon,
    label: 'Dashboard',
    path: '/dashboard'
},
{
    img: data_icon,
    label: 'Data',
    path: 'dashboard/data/topup'
},
{
    img: airtime_icon,
    label: 'Airtime',
    path: 'dashboard/airtime/topup'
},
{
    img: electric_subscription_icon,
    label: 'Electricity',
    path: 'dashboard/bill/topup'
},
{
    img: tv_subscription_icon,
    label: 'Subscription',
    path: 'dashboard/cable/topup'
},
{
    img: bulksms_icon,
    label: 'Bulk SMS',
    path: '/dashboard/send/bulksms'
},
{
    img: airtime_to_cash_icon,
    label: 'Airtime To Cash',
    path: 'dashboard/convert/airtime_cash'
},
{
    img: settings_icon,
    label: 'Settings',
    path: 'settings'
},
{
    img: developer_api_icon,
    label: "Developer's",
    path: 'developer'
},

];

export const sidebarItems = [
    {
        icon: <FiGrid />,
        label: 'Dashboard',
        path: '/dashboard'
    },
    {
        icon: <IoWifiOutline />,
        label: 'Data',
        path: '/dashboard/data/topup'
    },
    {
        icon: <GiSmartphone />,
        label: 'Airtime',
        path: '/dashboard/airtime/topup'
    },
    // {
    //     icon: <GiSmartphone />,
    //     label: 'EPins-Recharge',
    //     path: '/dashboard/airtime/e-pins'
    // },
    {
        icon: <RiLightbulbFlashLine />,
        label: 'Electricity',
        path: '/dashboard/bill/topup'
    },
    {
        icon: <MdLaptopChromebook />,
        label: 'Subscription',
        path: '/dashboard/cable/topup'
    },
    {
        icon: <CiMail />,
        label: 'Bulk SMS',
        path: '/dashboard/send/bulksms'
    },
    {
        icon:<GiTakeMyMoney />,
        label: 'Airtime To Cash',
        path: '/dashboard/convert/airtime_cash'

    },
    {
        icon: <IoSettingsOutline />,
        label: 'Settings',
        path: '/dashboard/settings'
    },
    {
        icon: <BsRobot />,
        label: 'Ziri (WhatsApp Bot)',
        path: '/dashboard/ziri'
    },
    {
        icon: <RiPriceTag2Fill />,
        label: "Data Api Prcing",
        path: '/dashboard/api-pricing'
    },
    {
        icon: <FaLaptopCode />,
        label: "Developer's API",
        path: '/dashboard/developer'
    },
    {
        icon:<IoMdLogOut />,
        label: "Logout",
        // path: '/logout'
    }
];



export const developers = [ 
{
    icon: <SiAmazonapigateway />,
    label: 'RestFul API',
    description: 'RestFul Api is a type of web service that is lightweight and easy to use. That is why we choose to use it for our API.',
},
{
    icon: <FaFilterCircleDollar />,
    label: 'Zero Setup fees',
    description: 'No Setup fees with PayeeLord',
},
{
    icon: <MdOutlineSecurity />,
    label: 'API Security',
    description: 'We have a secure API that is easy to use and secure. 100% your details is secure with us.',
},
{
    icon: <TbShoppingCartCode />,
    label: 'Access Merchants',
    description: 'You have access to all our API in one place. You can access all our API with just one click.',
},
{
    icon: <HiBanknotes />,
    label: 'Our Products at cheapest rates',
    description: 'With our API, it is certain you will make more money with us. We offer #1 Cheapest VTU in Nigeria.',
},
{
    icon: <MdSupportAgent />,
    label: '24/7 Support',
    description: 'Our support team and technical team are available 24/7 to assist you with any issues you may have.',
},

];




export  const settings = [
    {
        label: 'Profile',
      
    },
    {
        label: 'Change Password',
       
    },
    {
        label: 'Change Pin',
       
    },
    {
        label: 'Change Bank Details',
       
    },
    {
        label: 'Notifications',
       
    },
    {
        label: 'Referrals',
       
    },
    {
        label: 'Manage Beneficiary',
       
    },
    {
        label: 'Developer Api',
       
    },
    {
        label: 'Help/Support',
       
    },
    
    {
        label: 'Logout',
       
    },
];

export const quickAccess = [
    {
       
        text: 'Quick Access'

    },
    {
        img: data_icon,
        label: 'Data',
        path: 'dashboard/data/topup'

    },
    {
        img: airtime_icon,
        label: 'Airtime',
        path: 'dashboard/airtime/topup'

    },
    {
        img: electric_subscription_icon,
        label: 'Electricity',
        path: 'dashboard/bill/topup'

    },
    {
        img: tv_subscription_icon,
        label: 'Subscription',
        path: 'dashboard/cable/topup'

    },
    {
        img: bulksms_icon,
        label: 'Bulk SMS',
        path: 'dashboard/send/bulksme'

    },
    {
        img: airtime_to_cash_icon,
        label: 'Airtime to Cash',
        path: 'dashboard/convert/airtime'

    },

];

export const heroLeft = [
    {
        title: 'Stay Connected, Stay Smart !!!',
        text: `We offer Cheap, Long Lasting and Seamless Solutions for Data, Airtime, Cable TV Subscriptions (DSTV, GOTV, & STARTIMES) 
and Electricity bill payments at your fingertips.`
    }
];


export const downloadApps = [
    {
        textOne: 'Download payeelord',
        textTwo: 'Mobile Application for',
        textThree: 'Android and IOS easily.',
        appStore: appstore,
        playStore: playstore,
    }
];

export const Heroreview = [

    {
        img: automated,
        title: 'We Are Automated',
        text: `Our service delivery and wallet funding are
fully automated, ensuring that your purchases
are delivered to you in the blink of an eye.`
    },
    {
        img: customercare,
        title: 'Customer Support',
        text: `Our platform is fully optimized for reliability and dependability, ensuring that you receive 100% value for every transaction you conduct with us.`
    },
    {
        img: reliableImg,
        title: 'Reliable & Available',
        text: `Our customers hold premium importance to us, making their satisfaction our utmost priority. Our customer service is just a click away, available 24/7 to assist you.`
    },
];


export const features = [

{
    img: data_icon,
    title: 'All Network Data',
    text: `Begin taking advantage of this exceptionally affordable data plan for your internet browsing data bundle.`,
    path: '/dashboard/data/topup'
},
{
    img: tv_subscription_icon,
    title: 'Cable Subscription',
    text: `Get your cable subscription activated instantly with a great discount.
`,
    path: '/dashboard/cable/topup'
},
{
    img: airtime_icon,
    title: 'All Network Airtime',
    text: `Ensuring affordable and convenient online recharges available 24/7.`,
    path: '/dashboard/airtime/topup'
},
{
    img: electric_subscription_icon,
    title: 'Electric Payment',
    text: `We use an application designed a testing game to keep away`,
    path: '/dashboard/bill/topup'
},
{
    img: bulksms_icon,
    title: 'Bulk SMS',
    text: `Ensuring cost-effective and convenient online recharges available round the clock`,
    path: 'dashboard/send/bulksme'
},
{
    img: airtime_to_cash_icon,
    title: 'Convert Airtime To Cash',
    text: `Transform your airtime into cash at an attractive rate, or exchange it for airtime on a different network.`,
    path: 'dashboard/convert/airtime'
},

];


export const rotatingImages = [
    {
        img1:circle,
        img2: happyboy_hero 
    }
];


export const SatisfiedCustomers = [
{
    img: customer_feedback_icon,
    title: "100K",
    text: "Satisfied Customers"
},
{
    img: circle_plus_icon,
    title: "190K",
    text: "Active Users"
},
{
    img: arrow_icon,
    title: "100K",
    text: "Downloads"
},
{
    img: efficiency_icon,
    title: "100%",
    text: "Efficiency & Reliable"
},

];

export const HowItWorkItems = [
{
    img: howitwork_line,
    title: 'Login or Register for Free',
    text: `If you are already existing user, Login to your account by entering your details.
If you are a new user, Create an account with us by
“Registering for Free”.`
},
{
    img: howitwork_line,
    title: 'Dashboard',
    text: `Then access your dashboard. In your dashboard, you will see all our available services.`
},
{
    img: howitwork_line,
    title: 'Place Order',
    text: `Simply place an order for any of our services you'd like to buy. They're all very budget-friendly, and delivery is almost immediately without any delay.`
},
// {
//     img: howitwork_line,
//     title: 'Login or Register for Free',
//     text: `If you are already existing user, Login to your account by entering your details.
// If you are a new user, Create an account with us by
// “Registering for Free”.`
// },

];



export const CustomerreviewItems = [
{
img: mavroy_review,
text: `I always turn to this site for my bill payments because it makes the process faster and easier. Their services consistently deliver a great experience, and I appreciate their effective customer service. Highly recommend!`,
name: "-Sunday Akhademe"

},
{
    img: caroline_review,
text: `Excellent service! I love how easy it is to fund my wallet on this payeelord. It's quick and hassle-free. The entire process runs smoothly, and I can always count on them to be reliable. If you're looking for a seamless experience, I highly recommend giving them a try!`,
name: "-Ayodele Abimbola"

},
{
    img: juliet_review,
text: `Postscholar now known as payeelord has always been reliable.
Sincerely your services are highly recommended and I must confess that you are the best.`,
name: "-Juliet Madaki"

},
{
    img: ima_abasi_review,
text: `Best website I've ever experienced with flawless transactions. A go-to software for premium telecom services. Payeelord is the best!`,
name: "-Ima-Abasi Umoren"

},
{
    img: mayowa_review,
text: `I rate this site 5 stars for it's swift customer response, easy funding and fast connectivity.  The entire team of this site are doing a great job and I highly recommend them for your smooth customer experience journey`,
name: "-Mayowa Busayo"

},
];


export const FrequentQuestLeft = [

    {
        bigText: `Do You Have Any Question? We’ve Got you Covered !!!`,
        smallText: `Can’t find what you are looking for ? Don’t hesitate to reach out to us. We are always available.`
    }
];



export const ZiriFrequentQuestLeft = [
    {
        bigText: `Meet Ziri, Your Virtual Assistant!`,
        smallText: `Have questions about using Ziri? Our AI assistant is here to help you 24/7 with all your queries and transactions.`
    }
];

export const ZiriFrequentQuestRight = [
{
question: 'Who is Ziri of PayeeLord?',
answer: `Ziri is a PayeeLord WhatsApp bot that is extremely effective at bringing seamless transactions to you, all on WhatsApp. Chat Ziri to top up your airtime, subscribe to data, pay utility bills and recharge card pin, fund your wallet, and many more.`,

},
{
question: 'How fast is transaction with Ziri?',
answer: `Transactions with Ziri is within 5seconds. Once you send your request to Ziri, he will process it immediately and respond to you within 5 seconds.`,

},
{
question: 'How active is Ziri online?',
answer: `Ziri is active 24hrs online. He is always ready to process your request at anytime.`,

},
{
question: 'Can I use any WhatsApp number to message Ziri?',
answer: `No, Ziri can only process requests from WhatsApp number that you have submitted as your WhatsApp number in your PayeeLord account.
If you have not updated your WhatsApp number in your PayeeLord account, click the button below to login to your account and update your WhatsApp number`,

},
{
question: 'Can I send any request to Ziri?',
answer: `No, Ziri is a bot and not a human. He can only process some certain requests that he understand.`,
},
{
question: 'What are the requests Ziri can process?',
answer: `Kindly send Hi or Hello to Ziri to see all the requests Ziri can process.`,
},

];

export const servicesData = [
    {
      category: "Data Subscription",
      requests: [
        {
          name: "DATA",
          details: "Send this request to Zidi to see all available data network providers",
        },
        {
          name: "DATA NETWORK",
          details: "Send this request to Zidi to see all available data bundles, prices and planid for the network. e.g DATA MTN",
        },
        {
          name: "DATA NETWORK BENEFICIARY PLANID",
          details: "Subscribe data directly to any of MTN, Glo, 9mobile, Airtel networks. e.g DATA MTN 09088992200 2 (MTN 1GB of Data to 09088992200)",
        },
      ],
    },
    {
      category: "Airtime Recharge",
      requests: [
        {
          name: "AIRTIME",
          details: "Send this request to Zidi to see all available airtime networks",
        },
        {
          name: "AIRTIME NETWORK BENEFICIARY AMOUNT",
          details: "Recharge airtime directly to any of MTN, Glo, 9mobile, Airtel networks. e.g AIRTIME MTN 09088992200 100 (MTN N100 of airtime to 09088992200)",
        },
      ],
    },
    {
      category: "Cable TV Subscription",
      requests: [
        {
          name: "BILL",
          details: "Send this request to Zidi to see all available cable tv providers",
        },
        {
          name: "BILL PACKAGE",
          details: "Send this request to Zidi to see all available cable tv bundles and prices for the package. e.g BILL GOTV",
        },
        {
          name: "BILL PACKAGE Smartcardnumber AMOUNT",
          details: "Subscribe Cable TV bill to any of DSTV, GOTV, STARTIMES package. e.g BILL GOTV 7035230090 3308 (GOtv Jolli on 7035230090)",
        },
      ],
    },
    {
      category: "Electricity Payment",
      requests: [
        {
          name: "ELECT",
          details: "Send this request to Zidi to see all available electricity packages",
        },
        {
          name: "ELECT PACKAGE METERTYPE METERNUMBER AMOUNT",
          details: "Make electricity payment to any of the electricity packages available with metertype of Prepaid or Postpaid e.g ELECT AEDC PREPAID 45036572027 3000 (N3000 AEDC prepaid electricity on 45036572027)",
        },
      ],
    },
{
  category: "Education Bill Payment",
  requests: [
    {
      name: "EDU",
      details: "Send this request to Zidi to see all available educational package",
    },
    {
      name: "EDU PACKAGE",
      details: "Send this request to Zidi to see all available educational bundles and prices for the package. e.g EDU NECO",
    },
    {
      name: "EDU PACKAGE QUANTITY",
      details: "Purchase educational result checker of NECO, WAEC package. e.g EDU NECO 1(1 quantity of NECO result checker)",
    },
  ],
},
{
  category: "Recharge Card Pin Payment",
  requests: [
    {
      name: "EPIN",
      details: "Send this request to Zidi to see all available recharge card networks",
    },
    {
      name: "EPIN PACKAGE", 
      details: "Send this request to Zidi to see all available educational bundles and prices for the package. e.g EDU NECO",
    },
    {
      name: "EPIN NETWORK AMOUNT QUANTITY COMPANYNAME",
      details: "Purchase recharge card pin of MTN, GLO, 9MOBILE, AIRTEL network. e.g EPIN MTN 100 2 ALOCOM (2 quantity of MTN N100 recharge card with ALOCOM as company name to show on generated pin)",
    },
  ],
},
  ];
  
 
  

export const FrequentQuestRight = [
    {
    question: 'What is payeelord?',
    answer: `PayeeLord is a Nigerian Based company that offers cheapest data, cable subscription, utility bill.`,
    
    },
    {
    question: 'Do you offer Resell Services ?',
    answer: `Yes, our main target is Reseller`,
    
    },
    {
    question: 'Can I recharge for someone else ?',
    answer: `Yes`,
    
    },
    {
    question: 'Do you have an App where I can get Data easily ?',
    answer: `Yes, we have Application on Google Paystore`,
    
    },
    {
    question: 'What are your charges like ?',
    answer: `Manua wallet funding is free, while 9PSB and Bankly charge charged #30 and 1% respectively`,
    
    },
    
    ];
    
export const services = {
    title: 'Services',
    items: [
      {
        label: 'Data Bundle',
        path: '/dashboard/data/topup',
      },
      {
        label: 'Airtime Top Up',
        path: '/dashboard/airtime/topup',
      },
      {
        label: 'Electricity Payment',
        path: '/dashboard/bill/topup',
      },
      {
        label: 'Subscription Cable',
        path: '/dashboard/cable/topup',
      },
      {
        label: 'Bulk SMS',
        path: '/dashboard/send/bulksme',
      },
      {
        label: 'Airtime To Cash',
        path: '/dashboard/convert/airtime',
      },
    ],
  };

  
  export const earnWithpayeelord = {
    title: 'Earn With payeelord',
    items : [
        {
            label: 'Become an Agent',
            path: '#'
        },
        {
            label: 'Start Earning',
            path: '#'
        },
        {
            label: 'Integrate Our API',
            path: 'https://documenter.getpostman.com/view/28077168/2sAY4siPVW'
        },
        {
            label: 'Partner with Us',
            path: '#'
        },
        {
            label: 'Terms and Condition',
            path: '#'
        },
        {
            label: 'Privacy Policy',
            path: '#'
        },
    ]

  };


  export const WebsiteLink = {
    title: 'Website Links',
    items: [
        {
            path: '/',
            label: 'Home'
        },
        {
            path: '/services',
            label: 'Our Services'
        },
        {
            path: '/about',
            label: 'About Us'
        },
        {
            path: '/ziri',
            label: 'Ziri(WhatsApp Bot)'
        },
        {
            path: '/developer',
            label: 'Developer Api'
        },
        {
            path: '/contact',
            label: 'Contact Us'
        },
        {
            path: '/login',
            label: 'Login'
        },
        {
            path: '/register',
            label: 'Register For Free'
        },
    ]
  };

  export const Locations = {
            title: 'Location',
            items: [ {
            address: `D Layout Behind Crush Rock Mpape Und St. Mpape FCT Central`,
            imgOne: playstore,
            imgTwo: appstore,
            }
            ]
  };


  export const firstColumn = {
    logo: logo,
    text: `Register to purchase cost-effective data plans easily, alongside cable and utility bill subscriptions.`
  };

  export const socialIcons = [
    {
        img: x_icon,
        path: '',

    },
    {
        img: linkind_icon,
        path: '',

    },
    {
        img: facebook_icon,
        path: '',

    },
    {
        img: instagram_icon,
        path: '',

    },
  ];

  export const Contactitems = {
    title: 'Contact Us',
    items: [
        {
            img: phone_icon,
            label: "+2348144446509"
        },
        {
            img: mail_icon,
            label: "support@payeelord.com"
        },
    ]
  };


  export const mtnPricingItems = {
    title: mtn_icon,
    items: [

        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
    ]
  }
  export const gloPricingItems = {
    title: glo_icon,
    items: [

        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        // {
        //     volume: '1gb',
        //     price: 'N200',
        //     validity: '30 days'
        // },
        // {
        //     volume: '1gb',
        //     price: 'N200',
        //     validity: '30 days'
        // },
       
    ]
  };
  export const airtelPricingItems = {
    title: airtel_icon,
    items: [

        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        // {
        //     volume: '1gb',
        //     price: 'N200',
        //     validity: '30 days'
        // },
        // {
        //     volume: '1gb',
        //     price: 'N200',
        //     validity: '30 days'
        // },
        // {
        //     volume: '1gb',
        //     price: 'N200',
        //     validity: '30 days'
        // },
       
    ]
  };
  export const nineMobilePricingItems = {
    title: nine_mobile_icon,
    items: [

        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
        {
            volume: '1gb',
            price: 'N200',
            validity: '30 days'
        },
       
    ]
  };


  export const aboutTopItems = 
    {
        imgOne: aboutWoman,
        imgTwo: aboutBoy,
        textOne: `It was founded by a team of tech-savvy individuals with a passion for simplifying everyday tasks, our company was born out of a desire to revolutionize the way people access and manage their digital services. We believe that technology should empower, not complicate, and that's exactly what we strive to achieve with our user-friendly platform.`,
        textTwo: `From staying connected with reliable data and airtime top-ups to enjoying uninterrupted entertainment with cable subscriptions, we've got you covered. But that's not all we also offer hassle-free electric bill payments and efficient bulk SMS solutions for businesses, ensuring that you never miss an important deadline or communication.`,
        textThree: `What sets us apart is our unwavering commitment to quality and customer satisfaction. We meticulously vet and partner with industry-leading service providers to offer you the best deals and seamless experiences. Our platform is designed with your convenience in mind, allowing you to manage all your digital needs from a single dashboard, anytime, anywhere.`,
        textFour: `What sets us apart is our unwavering commitment to quality and customer satisfaction. We meticulously vet and partner with industry-leading service providers to offer you the best deals and seamless experiences. Our platform is designed with your convenience in mind, allowing you to manage all your digital needs from a single dashboard, anytime, anywhere.`
    };
   

  export const aboutButtomItems = {
   
    //     imgOne: aboutWoman,
    //     imgTwo: aboutBoy,
    //     textOne: `It was founded by a team of tech-savvy individuals with a passion for simplifying everyday tasks, our company was born out of a desire to revolutionize the way people access and manage their digital services. We believe that technology should empower, not complicate, and that's exactly what we strive to achieve with our user-friendly platform.`,
    //     textTwo: `From staying connected with reliable data and airtime top-ups to enjoying uninterrupted entertainment with cable subscriptions, we've got you covered. But that's not all we also offer hassle-free electric bill payments and efficient bulk SMS solutions for businesses, ensuring that you never miss an important deadline or communication.`,
    //     // textThree: `What sets us apart is our unwavering commitment to quality and customer satisfaction. We meticulously vet and partner with industry-leading service providers to offer you the best deals and seamless experiences. Our platform is designed with your convenience in mind, allowing you to manage all your digital needs from a single dashboard, anytime, anywhere.`,
    //     textThree: `What sets us apart is our unwavering commitment to quality and customer satisfaction. We meticulously vet and partner with industry-leading service providers to offer you the best deals and seamless experiences. Our platform is designed with your convenience in mind, allowing you to manage all your digital needs from a single dashboard, anytime, anywhere.`
    // },
   
        imgOne: aboutMan,
        textOne: `Moreover, we take pride in being fully registered with the Corporate Affairs Commission, ensuring that our operations are legitimate, transparent, and adhere to the highest standards of corporate governance.`,
        textTwo: `At payeelord, we're more than just a service provider; we're your trusted companion in the digital realm. Our dedicated team of customer support professionals is always ready to assist you with any queries or concerns, ensuring that your experience with us is nothing short of exceptional.`,
        textThree: `Join us on this journey of digital convenience and let us be your gateway to a seamless, connected life. Welcome to payeelord – where technology meets simplicity, and your digital needs are our top priority.`
    };


export const contactFormItems = [
{
    img: phone_icon,
    text: '+2348144446509'
},
{
    img: location_icon,
    // text: 'D Layout Behind Crush Rock Mpape Und St. Mpape FCT Central'
},
// {
//     img: location_icon,
//     text: h Rock Mpape Und St. Mpape FCT Central'
// },'D Layout Behind Crus
{
    // img: mail_icon,
    img: phone_icon,
    text: 'support@payeelord.com'
},
// {
//     // img: mail_icon,
//     text: 'Mpape '
// },

];



export const transactions = [
  
    {
        label: 'Details'
    },
    {
        label: 'Amount'
    },
    {
        label: 'Date/Time'
    },
    {
        label: 'Transaction No'
    },
    {
        label: 'Status'
    },
    {
        label: 'Invoice'
    },
];

export const transaction1 = 
[
    {
        img: data_txt_icon,
        detail: "MTN Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "GLO Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "MTN Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "Airtel Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
]

export const transaction2 = 
[
    {
        img: data_txt_icon,
        detail: "MTN Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "GLO Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "MTN Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "Airtel Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
];
export const transaction3 = 
[
    {
        img: data_txt_icon,
        detail: "MTN Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "GLO Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "MTN Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "Airtel Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
];
export const transaction4 = 
[
    {
        img: data_txt_icon,
        detail: "MTN Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "GLO Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "MTN Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "Airtel Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "failed",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
];
export const transaction5 = 
[
    {
        img: wallet_txt_icon,
        detail: "Wallet Funding",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "pending",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "GLO Airtime",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: airtime_cash_txt_icon,
        detail: "Aitime to Cash",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "pending",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
    {
        img: data_txt_icon,
        detail: "Airtel Data",
        nature: "Debit",
        amount: "#7,000.00",
        date: "20 Setp, 2023",
        time: "09:43am",
        trx_no: "KYI16941003976220538739708559794",
        status: "success",
        invoice_view: <FaRegEye />,
        invoice_download: <FaDownload />
        },
];





