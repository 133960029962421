import { useEffect, useState } from 'react';
import {  port_elect_icon } from '../../assets';
// import { Link } from 'react-router-dom';
import Select from 'react-select';
import BuyPinPopup from '../../components/dashboards/BuyPinPopup';
import { FailureAlert, SuccessAlert } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import ValidateConfirmation from './ValidateConfirmation';
import { payBill, verifyMeter } from '../../controller/buyElectricSlice';
import OutOfFund from '../../components/dashboards/OutOfFund';

const PayYolaBill 
 = () => {
    const dispatch = useDispatch();
    const { loading, message, error, validateMessage} = useSelector((state) => state.electricBill);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [meterNumber, setMeterNumber] = useState('');
    const [failurePopup, setFailurePopup] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);
    const [confirmPaymentPopup, setConfirmPaymentPopup] = useState(false);
    const [confirmValidatePopup, setConfirmValidatePopup] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [phone, setPhoneNumber] = useState('');
    const [meterType, setMeterType] = useState('');
    const [amount, setAmount] = useState('');
    const [pinPopup, setPinPopup] = useState(false)

    useEffect(() => {
        if (error) {
            setFailurePopup(true);
        }
    }, [error]);

    useEffect(() => {
        if (message) {
            setSuccessPopup(true);
        }
    }, [message]);
    useEffect(() => {
        if (validateMessage) {
            setConfirmValidatePopup(true);
        }
    }, [validateMessage]);

    const handleSetBeneficiary = (phone) => {
        setSelectedBeneficiary(phone);
        setMeterNumber(phone);
        setShowBeneficiary(false); // Hide beneficiary list after selection
    };

    const handleBeneficiaryToggle = () => {
        setShowBeneficiary(!showBeneficiary);
    };

    const handleMeterNumberChange = (e) => {
        setSelectedBeneficiary(null); // Clear the selected beneficiary if the user starts typing
        setMeterNumber(e.target.value);
    };

    const meter_type = [
        { id: 1, meterType: "Prepaid", value: "prepaid" },
        { id: 2, meterType: "Postpaid", value: "postpaid" }
    ];

    // const beneficiaries = [
    //     { phone: '0813344557788' },
    //     { phone: '0813344557799' },
    //     { phone: '0813344557700' },
    //     { phone: '0813344557711' },
    //     { phone: '0813344557722' }
    // ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B'
            }
        })
    };


    const handleMeterTypeChange = (selectedOption) => {
        setMeterType(selectedOption.value);
    };

    const closePinPopup = () => setPinPopup(false);
    const closeFailurePopup = () => setFailurePopup(false);
    const closeSuccessPopup = () => setSuccessPopup(false);

    const handlePin = ()=>{

        setPinPopup(true)
    }

    const billPayload = {
        disconame: 11,
        meternumber: meterNumber,
        mtype: meterType,
    };

    const payloadBill = {
        disconame: 11,
        meternumber: meterNumber,
        mtype: meterType,
        amount: amount,
        phone: phone,

    }

const address = message?.Address;
const customerName = message?.CustomerName;


    const handleValidate = async () => {
        try {
            const resultAction = await dispatch(verifyMeter(billPayload));
            if (verifyMeter.fulfilled.match(resultAction)) {
                // Handle success
            }
        } catch (error) {
        }
    };

    const handlePayBill = async () => {
        try {
            const resultAction = await dispatch(payBill(payloadBill));

            if (payBill.fulfilled.match(resultAction)) {
                // Handle success
                
            }
        } catch (error) {
        }
    };

    if (loading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
                <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
                  <div className="loader" />

                </div>
            </div>
        );
    }
    

    return (
        <>
            <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto shadow-lg rounded-xl border-solid border-[#E5E5E5]'>
                <div className='flex items-center justify-center pb-10 w-full gap-2'>
                            <img src={port_elect_icon} alt='Portharcourt Icon' />
                            <div className='items-start flex-col'>
                                <h1 className='text-base font-bold'>Portharcourt Electricity Distribution (PHED)</h1>
                                <p className='text-gray-400 text-xs'>Prepaid and Postpaid PHED payment.</p>
                            </div>
                        </div>
                    <form onSubmit={handleValidate} className='flex justify-between gap-5'>
                        <div className='flex flex-col gap-4 w-full'>
                            <div>
                                <div className='flex justify-between w-full items-center pb-1'>
                                    <p className='text-sm font-semibold'>Meter Number</p>
                                    {/* <Link to='' className='text-[#FF7D3B] text-sm font-semibold' onClick={handleBeneficiaryToggle}>Choose Beneficiary</Link> */}
                                </div>
                                <input type="text" required placeholder='Enter Meter Number' value={meterNumber} onChange={handleMeterNumberChange} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                {/* <div className='flex justify-between w-full items-center mt-1'>
                                    <p className='text-xs font-semibold text-nowrap'>Save Number as a Beneficiary</p>
                                    <button type='button' onClick={() => setIsBeneficiary(!isBeneficiary)}>
                                        {isBeneficiary ? <PiToggleRightFill className='text-[#FF7D3B] text-base font-bold' /> : <PiToggleLeftFill className='text-[#E5E5E5] text-base font-bold' />}
                                    </button>
                                </div> */}
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>Meter Type</p>
                                <Select
                                    options={meter_type.map(meter => ({ label: meter.meterType, value: meter.value }))}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    className="w-full"
                                    onChange={handleMeterTypeChange}
                                    placeholder="Select Meter Type"
                                />
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>Phone Number</p>
                                <input type="text" maxLength="11" required value={phone} onChange={(e) => setPhoneNumber(e.target.value)} placeholder='Enter Phone Number' className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>Amount</p>
                                <input type="text" required value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder='Enter Amount' className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                            </div>
                            <div>
                                <p className='text-xs font-semibold pb-1'>Charges</p>
                                <p className='bg-[#FFF3ED] py-2 pl-2 rounded-md mt-1 italic text-slate-500 text-xs font-semibold'>You will be charged #30 for this.</p>
                            </div>
                            <button className='w-auto h-[46px] font-semibold px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white flex items-center justify-center mx-auto'>
                                Validate
                            </button>
                        </div>
                    </form>
                    {/* {showBeneficiary && (
                        <div className='mt-3'>
                            <p className='text-xs font-semibold pb-1'>Select a Beneficiary</p>
                            {beneficiaries.map((beneficiary, index) => (
                                <button key={index} className='text-sm block mb-2 text-left w-full border-b' onClick={() => handleSetBeneficiary(beneficiary.phone)}>
                                    {beneficiary.phone}
                                </button>
                            ))}
                        </div>
                    )} */}
                </div>
                <OutOfFund/>
            </div>
           
           

            <ValidateConfirmation
        isVisible={confirmValidatePopup}
        customerName={customerName}
        address={address}
        onConfirm={() => {
            handlePin();
            setConfirmValidatePopup(false);
        }}
        onCancel={() => setConfirmValidatePopup(false)}
    />
    {/* <ConfirmationPopup
        isVisible={confirmPaymentPopup}
        message={You are about to send a bulk SMS to ${phoneNumber.split(',').length} recipients. The total cost for this transaction is ₦${totalCost}. Confirm Now.}
        onConfirm={() => {
            handleBuy();
            setConfirmPopup(false);
        }}
        onCancel={() => setConfirmPopup(false)}
    /> */}

            <BuyPinPopup handleBuy={handlePayBill} isVisible={pinPopup} onClose={closePinPopup} />
            <SuccessAlert isVisible={successPopup} onClose={closeSuccessPopup} message={message} />
            <FailureAlert isVisible={failurePopup} onClose={closeFailurePopup} message={error} />
        </>
    );
};

export default PayYolaBill 
;
