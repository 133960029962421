import DataTable from 'react-data-table-component';
import { servicesData } from '../constants';

const columns = [
    {
        name: 'Services',
        selector: row => row.category,
        sortable: true,
    },
    {
        name: 'Requests',
        cell: row => (
            <div>
                {row.requests.map((request, index) => (
                    <div key={index}>{request.name}</div>
                ))}
            </div>
        ),
        sortable: true,
    },
    {
        name: 'Details',
        cell: row => (
            <div>
                {row.requests.map((request, index) => (
                    <div key={index} className='text-base'>{request.details}</div>
                ))}
            </div>
        ),
        sortable: true,
    },
];

const ZiriCommand = () => {
    return (
        <div>

           <p className='pb-5 text-base font-medium'>
             Discover Ziri's powerful capabilities! 🚀 Explore our interactive command table below - 
             simply swipe left to unveil the full range of features at your fingertips.
           </p>
            <DataTable
                title="Ziri Commands"
                columns={columns}
                data={servicesData}
                dense
                highlightOnHover
                pointerOnHover
                responsive
                striped
                customStyles={{
                    header: {
                        style: {
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            color: '#2c3e50',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        },
                    },
                    headRow: {
                        style: {
                            backgroundColor: '#f8f9fa',
                            borderBottomWidth: '2px',
                            borderBottomColor: '#dee2e6',
                        },
                    },
                    rows: {
                        style: {
                            minHeight: '60px',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#f1f5f9',
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default ZiriCommand;
