import React from 'react';
import QuickAccess from './QuickAccess';
import Button from './Button';
import { downloadApps, heroLeft } from '../constants';
import { HeroImage, ladyHero } from '../assets';
import { Link } from 'react-router-dom';

const HeroTop = () => {
  return (
    <section className="w-full relative h-auto mt-3 md:mt-[100px]  ">
      <div className="w-full h-auto px-[20px] lg:px-[79px] xl:px-[100px] flex flex-col   md:justify-between md:flex-row gap-[20px] lg:gap-[25px] xl:gap-[50px] ">
        <div>
          <QuickAccess />
        </div>
      

        <div className='flex flex-col md:flex-row  gap-9 md:gap-5  justify-between items-center w-full pt-5 lg:pb-[90px] '>
          <div className="flex flex-col items-center   md:items-start w-full lg:w-full xl:w-[468px] gap-[30px]  lg:gap-[56px] justify-center ">
            {heroLeft.map((item, index) => (
              <div key={index} className="lg:text-left flex flex-col gap-[8px]">
                <h1 className="text-[32px] text-center lg:text-left lg:text-[40px] xl:text-[60px] leading-[44px] lg:leading-[50px] xl:leading-[81px] font-[700]">{item.title}</h1>
                <p className="text-[14px] lg:text-[18px] text-center lg:text-left xl:text-[22px] leading-[24px] lg:leading-[24px] font-[400]">{item.text}</p>
              </div>
            ))}
            <div className='flex flex-col gap-4'>
            <Link className=' md:hidden' to='/login'> 
            <Button text="Login Here" />
            </Link>
            <Link to='/register'> 
            <Button text="Register For Free" />
            </Link>
            </div>
          </div>
          {/* <div className='pb-[120px] lg:pb-0  w-full h-full lg:w-[402px] lg:h-[500px]'>
            <img src={HeroImage} alt="Lady Hero" className="" />
          </div> */}
          <div className='pb-[120px] lg:pb-0 w-full h-full lg:w-[402px] lg:h-[500px]'>
  <img 
    // src={ladyHero} 
    src={HeroImage} 
    alt="Lady Hero" 
    className="object-cover w-full h-full" 
  />
</div>
        </div>
      </div>

      {/* downloadApps parts */}

      <div className='absolute bottom-0 md:left-[200px] lg:left-[253px] xl:left-[299px] left-[39px] right-[39px]   xl:right-[100px] w-[calc(100% - 299px)] h-[150px] xl:px-[131px] bg-[#FFF3ED] rounded-t-[50px] px-[20px] py-[20px]'>
        {downloadApps.map((item, index) => (
          <div key={index} className='flex flex-col  xl:flex-row justify-center lg:gap-9 xl:gap-[106px] items-center mb-[20px]'>
          

         

            <div className='text-center text-[22px] :text-[24px] :flex lg:gap-0 leading-[32px] font-[700] xl:text-left'>
              <p className='text-[#FF7D3B]'>{item.textOne}</p>
              <p>{item.textTwo}</p>
              <p>{item.textThree}</p>
            </div>
            <div className='flex gap-4 lg:gap-[48px] mt-[10px] lg:mt-0'>
              
              <a  className='cursor-pointer' href='https://play.google.com/store/apps/details?id=com.abiodun.postscholar' target='_blank'><img src={item.playStore} alt={`Location Image One ${index}`} /></a>
              <a  className='cursor-pointer' title='coming soon' href='#' target='_blank'><img src={item.appStore} alt={`Location Image One ${index}`} /></a>
             
              
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroTop;
