import { useContext, useEffect, useState } from 'react';
import { google_icon, logo } from '../assets';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { register } from '../controller/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TbCircleDotted } from 'react-icons/tb';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisterPage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [refUsername, setRefUsername] = useState('');
  const [terms, setTerms] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errors, setErrors] = useState([]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordConfirmVisibility = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const ref = searchParams.get('ref');
    if (ref) {
      setRefUsername(ref);
    }
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setPasswordError(false);

    if (password !== confirmPassword) {
      setPasswordError(true);
      return;
    }

    const slicedUsername = username.split('@')[0];
    
    const userData = {
      f_name: firstName,
      l_name: lastName,
      username: slicedUsername,
      email,
      phone,
      address,
      password,
      ref_username: refUsername,
      terms,
    };

 


    try {
      const resultAction = await dispatch(register(userData));

      if (register.fulfilled.match(resultAction)) {
        // Facebook tracking event for registration
        window.fbq('track', 'CompleteRegistration', {
          value: 1,
          currency: 'NGN',
        });

        toast('Congratulations! Registration Successful');
        navigate('/login');
      } else {
        const errorMessage = resultAction.payload || resultAction.error.message;
        toast.error(errorMessage);
        setErrors([errorMessage]);
      }
    } catch (error) {
      toast.error(error.message);
      setErrors([error.message || 'Registration failed']);
    }
  };

  const handleSocialLogin = (provider) => {
    window.open(`https://api.payeelord.com/login/${provider}`, "_self");
  };

  const handleKeyDown = (e) => {
    // Allow backspace, delete, arrow keys, and numbers
    if (!/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      e.preventDefault();
    }
  };
  
  return (
    <div className='px-8 md:px-40 lg:px-64 w-full bg-[#FFF3ED] pb-[1px]' style={{ minHeight: '100vh' }}>
      <Link to="/" className='flex justify-center items-center pt-12'>
        <img src={logo} alt="Logo" />
      </Link>

      <div className='loginBg py-[60px] px-[30px] w-full lg:w-[360px] bg-white rounded-md flex flex-col items-center justify-center'>
        <form onSubmit={handleSubmit} className='w-full'>
          <div className='w-full items-center flex flex-col justify-center'>
            <h1 className='text-3xl font-bold mb-6'>Sign Up</h1>

            {/* Form Inputs */}
            {
  [
    { label: 'First Name', value: firstName, setter: setFirstName, error: error?.f_name },
    { label: 'Last Name', value: lastName, setter: setLastName, error: error?.l_name },
    { label: 'Email Address', value: email, setter: setEmail, error: error?.email },
    { label: 'Enter Username', value: username, setter: setUsername, error: error?.username },
    { label: 'Home Address', value: address, setter: setAddress, error: error?.address },
    { 
      label: 'Phone Number', 
      type: 'tel', 
      value: phone, 
      setter: setPhone, 
      error: error?.phone, 
      maxLength: "11", 
      onKeyDown: handleKeyDown,  // Fix here: pass the function reference, not the invocation
    
    }
  ].map(({ label, type = 'text', value, setter, error, onKeyDown, pattern, maxLength }) => (
    <div className="pb-4 w-full" key={label}>
      <input
        type={type}
        value={value}
        onChange={(e) => setter(e.target.value)}
        placeholder={label}
        className="border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black"
        required
        onKeyDown={onKeyDown}  // Apply the function reference here
        pattern={pattern}  // Apply the pattern here
        maxLength={maxLength}  // Apply the maxLength (for phone number)
      />
      {error && (
        <span className="text-red-700 text-sm font-semibold">{error}</span>
      )}
    </div>
  ))
}


            <div className='relative w-full pb-4'>
              <input
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Enter Password'
                className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                required
              />
              <div onClick={togglePasswordVisibility} className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'>
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>

            <div className='relative w-full'>
              <input
                type={passwordConfirmVisible ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder='Confirm Password'
                className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                required
              />
              <div onClick={togglePasswordConfirmVisibility} className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'>
                {passwordConfirmVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
            {passwordError && (
              <span className='text-red-700 text-xs font-semibold'>Password didn't match</span>
            )}

            <div className='pt-4 w-full'>
              <input
                type="text"
                value={refUsername}
                onChange={(e) => setRefUsername(e.target.value)}
                placeholder='Referral ID (Optional)'
                className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
              />
            </div>

            <div className='flex items-center w-full gap-2 pt-2'>
              <input type="checkbox" value={terms} onChange={(e) => setTerms(e.target.checked)} required />
              <p className='text-xs lg:text-[16px]'>I agree to the Terms and Conditions</p>
            </div>

            <div className='w-full flex flex-col items-center justify-center pt-6'>
              <button
                id="submitButton"
                type="submit"
                className='py-2 px-8 bg-[#FF7D3B] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
                disabled={loading}
              >
                {loading ? <div className='flex items-center gap-1 font-semibold justify-center'><TbCircleDotted className='animate-spin duration-200 text-lg' /> Authenticating...</div> : 'Sign Up'}
              </button>

              <div className='flex items-center gap-2 mt-4 w-full'>
                <p className='text-xs lg:text-[16px]'>Already have an account?</p>
                <Link to='/login' className='text-[#FF7D3B] text-[14px] font-bold'>Login</Link>
              </div>
            </div>

            <div className='w-full flex items-center px-10 gap-4 py-4'>
              <div className='border-[1px] border-solid w-full h-[2px]'></div>
              <p>Or</p>
              <div className='bg-[#CACACA] border-[1px] border-solid w-full h-[2px]'></div>
            </div>

            <div onClick={() => handleSocialLogin('google')} className='w-full flex items-center justify-center'>
              <div className='flex w-full rounded-xl h-[50px] shadow-md items-center justify-center border-2 border-solid border-[#FF7D3B] gap-3 hover:scale-95 transition-all duration-300'>
                <img src={google_icon} alt="Google Icon" />
                <Link >
                  <p className='text-[10px] font-semibold'>Sign In with Google</p>
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterPage;
