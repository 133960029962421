import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";


export const  paystackPayment = createAsyncThunk(
    'payWithCard/paystackPayment',
    async (amount, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');
  
        const response = await axiosClient.post(
          '/v1/paystack/payment',
          amount,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        let authorization_url = response.data.data; // Include authorization URL

        return authorization_url; // Return authorization URL

      } catch (error) {
        return rejectWithValue(
          error.response?.message || error.message || 'Something went wrong'
        );
      }
    }
  );

  const paystackPaymentSlice = createSlice({
    name: 'payWithCard',
    initialState: {
      loading: false,
      error: null,
      message: null,
    
    },
    reducers: {
      clearError(state) {
        state.error = null;
      },
      clearMessage(state) {
        state.message = null;
  
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(paystackPayment.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(paystackPayment.fulfilled, (state, action) => {
          state.message = action.payload;
          state.loading = false;
          state.error = null;
        })
        .addCase(paystackPayment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || action.error.message;
        });
        
    }
  });


  export const { clearError: cardPaymentError, clearMessage: cardPaymentMessage } =paystackPaymentSlice.actions;

export const cardPaymentReducer = paystackPaymentSlice.reducer;
  