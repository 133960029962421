import { Helmet } from 'react-helmet-async'
import { ZiriProfile } from '../assets'
import {  HeaderDescription, } from '../components'
import ZiriCommand from '../components/ZiriCommand'
import ZiriFreqAskQuestion from '../components/ZiriFreqAskQuestion'


const AboutZiri = () => {
  return (
    <>
    <Helmet>
      <title>Meet Ziri - Your WhatsApp Financial Assistant | PayeeLord</title>
      <meta name="description" content="Discover Ziri, PayeeLord's AI-powered WhatsApp financial assistant. Handle airtime top-ups, data subscriptions, bill payments, and more through simple WhatsApp messages." />
      <meta name="keywords" content="Ziri, PayeeLord, WhatsApp financial assistant, digital payments, airtime top-up, data subscription, bill payments, mobile banking, AI assistant" />
      
      {/* Open Graph Meta Tags for social sharing */}
      <meta property="og:title" content="Meet Ziri - Your WhatsApp Financial Assistant | PayeeLord" />
      <meta property="og:description" content="Discover Ziri, PayeeLord's AI-powered WhatsApp financial assistant. Handle airtime top-ups, data subscriptions, bill payments, and more through simple WhatsApp messages." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://payeelord.com/ziri" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Meet Ziri - Your WhatsApp Financial Assistant | PayeeLord" />
      <meta name="twitter:description" content="Discover Ziri, PayeeLord's AI-powered WhatsApp financial assistant. Handle airtime top-ups, data subscriptions, bill payments, and more through simple WhatsApp messages." />
      
      {/* Additional SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="PayeeLord" />
      <link rel="canonical" href="https://payeelord.com/ziri" />
    </Helmet>
   
    <div className='w-full h-auto pb-[40px] mt-[100px]'>

{/* <HeaderStroke text= 'About Us'/> */}
<div className='flex justify-center items-center pt-10'>
        <img className='rounded-full  bg-red-300 shadow-lg w-[5%] h-[5%]' src={ZiriProfile} height={50} width={50} alt="ziri" />
        </div>
<HeaderDescription text="Ziri, your personal WhatsApp financial assistant powered by PayeeLord. Experience the future of digital transactions right in your WhatsApp chat. From instant airtime top-ups and data subscriptions to hassle-free bill payments, wallet funding, and recharge card purchases - Ziri handles it all with just a few messages." title='Who is Ziri?' />


<div className='w-full px-[38px] lg:px-[100px] '>



<div>

  <div className='mt-14 lg:mt-[20px]'>
    {/* {aboutTopItems.map((item, index) => ( */}
      <div className='flex flex-col md:flex-row items-start  md:items-center justify-between gap-10 lg:gap-[85px] w-full  h-auto mb-20'>
      <ZiriFreqAskQuestion/>

      </div>
    {/* {aboutTopItems.map((item, index) => ( */}
    <ZiriCommand />
  
  </div>
</div>

</div>
    </div>
    </>
  )
}

export default AboutZiri