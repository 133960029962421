import { useState } from 'react';
import axiosClient from '../../controller/axiosClient';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ZiriProfile } from '../../assets';



const ZiriPage = () => {
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const { user} = useSelector((state) => state.auth); // Adjust based on your state structure
    const userWhatsappNumber = user?.whatsappNumber;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            await axiosClient.post('/v1/update/whatsap-number', {
                whatsappNumber: whatsappNumber
            });
            // Reset form and show success
            setWhatsappNumber('');
            toast.success('WhatsApp number updated successfully.');
        } catch (error) {
            console.error('Error updating WhatsApp number:', error);
            toast.error('Failed to update WhatsApp number');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='py-4 sm:py-6 md:py-[24px] px-3 sm:px-4 md:px-5 flex gap-3 sm:gap-4 md:gap-5 justify-between flex-col items-start'>
              
            
                
                    {/* <div className='flex items-center justify-center w-full gap-2'> */}
                        <div className='items-start flex-col py-3 gap-8 sm:py-4 px-2 sm:px-3 bg-[#FFF3ED] border-t-[10px] md:border-l-[10px] rounded-tl-lg rounded-tr-lg    rounded-br-lg rounded-bl-lg md:border-t-0 border-[#FF7D3B]'>

        <div className='flex justify-center items-center '>
        <img className='rounded-full  bg-red-300 shadow-lg w-[10%] h-[10%]' src={ZiriProfile} height={50} width={50} alt="ziri" />
        </div>                
        <div className='flex flex-col gap-1'>
                    <p className='text-xs'>
                    Meet Ziri, your personal WhatsApp financial assistant powered by PayeeLord. Experience the future of digital transactions right in your WhatsApp chat. From instant airtime top-ups and data subscriptions to hassle-free bill payments, wallet funding, and recharge card purchases - Ziri handles it all with just a few messages.
                    </p>
                    <Link to='/ziri' className='py-2 px-4 flex items-center rounded-md bg-[#FF7D3B] w-[150px] shadow-md text-white text-sm italic'>Learn more here</Link>
                </div>
<p className="text-black text-xs  my-2">Experience quick and easy transactions with Ziri, right on WhatsApp!</p>
                            <div className="bg-green-500 max-w-[150px]  flex items-center justify-center rounded-lg">
                            <a href='https://wa.me/message/2IUM62UNLW34N1' target='_blank'className='text-sm sm:text-base font-bold py-1.5 sm:py-2 px-2 text-white rounded-md'>CHAT WITH ZIRI</a>
                            </div>
                            <div className='mb-2 flex-col flex gap-2 items-start'>
                                <p className='text-xs'>To ensure secure transactions, Ziri exclusively processes requests from verified WhatsApp numbers registered in your PayeeLord profile. For optimal service access, please ensure your WhatsApp contact details are up-to-date</p>
                    <Link to='/dashboard/whatsapp' className='py-2 px-4 rounded-md bg-[#FF7D3B] shadow-md text-white text-sm italic'>Click Here to Update Your Whatsapp Number</Link>

                            </div>

                            <div className='mb-2 flex-col flex gap-2 items-start'>

                                <p className='text-xs'>Click the button below to watch a tutorial video on how to transact with Ziri</p>
                            <a href='#' title='coming soon' target='_blank' className=' px-4 rounded-md bg-[#FF7D3B] shadow-md text-white text-base py-2 italic font-semibold'>WATCH VIDEO</a>

                            </div>

                        </div>

                    {/* </div> */}
                    
                {/* </div> */}
            </div>
        </>
    );
}

export default ZiriPage;
