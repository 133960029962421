import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { getApiPring } from '../../controller/planSlice';

const columns = [
    {
        name: 'Data ID',
        selector: row => row.plan,
        sortable: true,
    },
    {
        name: 'Network',
        selector: row => row.network,
        sortable: true,
    },
    {
        name: 'Network ID',
        selector: row => row.networkId,
        sortable: true,
    },
    {
        name: 'Plan type',
        selector: row => row.plantype,
        sortable: true,
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'Size',
        selector: row => row.size,
        sortable: true,
    },
    {
        name: 'Validity',
        selector: row => row.validity,
        sortable: true,
    },
];

const ApiPricing = () => {
    const dispatch = useDispatch();
    const { apiPlans, apiPlanLoading } = useSelector((state) => state.dataPlan);

    useEffect(() => {
       
        
            dispatch(getApiPring());
        
      
    }, [dispatch]);

    return (
        <div>
            {apiPlanLoading ? (
                <p>Loading...</p>
            ) : (
<DataTable
    title="API Pricing Table"
    columns={columns}
    data={apiPlans}
    pagination
    theme="solarized"
    customStyles={{
        table: {
            style: {
                backgroundColor: '#f8f9fa',
                border: '1px solid #dee2e6',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#e9ecef',
                borderBottom: '2px solid #dee2e6',
                fontWeight: 'bold',
                color: '#495057',
            },
        },
        rows: {
            style: {
                fontSize: '14px',
                color: '#212529',
                '&:nth-of-type(odd)': {
                    backgroundColor: '#f8f9fa',
                },
                '&:nth-of-type(even)': {
                    backgroundColor: '#ffffff',
                },
                '&:hover': {
                    backgroundColor: '#e9ecef',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                },
            },
        },
        pagination: {
            style: {
                backgroundColor: '#ffffff',
                borderTop: '1px solid #dee2e6',
                padding: '16px',
            },
        },
    }}
    striped
    highlightOnHover
    pointerOnHover
    dense
/>
            )}
        </div>
    );
};

export default ApiPricing;
