import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { googleLoginCallback, loginSuccess } from '../../controller/authSlice';
import { toast } from 'react-toastify';


const SocialLoginCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Replaced useHistory with useNavigate

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const redirectPath = localStorage.getItem('redirectPath');
    // const redirectPath = localStorage.getItem('redirectPath');


    if (code) {

      
      // Dispatch the thunk with the code
      dispatch(googleLoginCallback({ code }))
        .unwrap()
        .then((user) => {
         
          dispatch(loginSuccess());
         
        
                 // Redirect to the previous page or home
          if(redirectPath){
        toast.success('Welcome back');

            navigate(redirectPath)
            localStorage.removeItem('redirectPath'); // Clear the redirect path after navigating

          }else{

            toast.success('Login Successful');
        
          navigate('/dashboard');
          }
        })
        .catch((error) => {
          // Handle the error here
          console.error('Login failed', error);
        });
    } else {
      console.error('No authorization code found in URL.');
    }
  }, [dispatch, location.search, navigate]);

  return (
    <div className="text-center mt-80 mb-80 flex flex-col justify-center h-[90px] items-center">
             <div className="loader" />


    </div>
  );
};

export default SocialLoginCallback;
