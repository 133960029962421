import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';

import historySlice from './historySlice';
import { cablePlanReducer, dataPlanReducer} from './planSlice';
import { airtimeConversionReducer, buyAirtimeReducer, buyDataReducer} from './buySlice';
import { slideShowReducer } from './slideShowSlice';
import { bulkSMSReducer } from './bulksmsSlice';
import { electricBillReducer } from './buyElectricSlice';
import { cablePaymentReducer } from './buyCableSlice';
import { cardPaymentReducer } from './cardPaymentSlice';


// Combine reducers into a root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  history: historySlice,
  dataPlan: dataPlanReducer,
  cablePlan: cablePlanReducer,
  buyData: buyDataReducer,
  Airtime: buyAirtimeReducer,
  slideImage: slideShowReducer,
  BulkSMS: bulkSMSReducer,
  electricBill: electricBillReducer,
  cablePayment: cablePaymentReducer,
  convertAirtime: airtimeConversionReducer,
  payWithCard: cardPaymentReducer,
});

// Apply persist configuration to the root reducer
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth',  'dataPlan', 'cablePlan', 'slideImage', 'history' ] // Persist both auth and history reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types for serializability checks
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
