import { useState } from 'react';
import axiosClient from '../../controller/axiosClient';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ZiriProfile } from '../../assets';


const Ziri = () => {
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const { user} = useSelector((state) => state.auth); // Adjust based on your state structure
    const userWhatsappNumber = user?.whatsappNumber;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            await axiosClient.post('/v1/update/whatsap-number', {
                whatsappNumber: whatsappNumber
            });
            // Reset form and show success
            setWhatsappNumber('');
            toast.success('WhatsApp number updated successfully.');
        } catch (error) {
            console.error('Error updating WhatsApp number:', error);
            toast.error('Failed to update WhatsApp number');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='py-4 sm:py-6 md:py-[24px] px-3 sm:px-4 md:px-5 flex gap-3 sm:gap-4 md:gap-5 justify-between flex-col md:flex-row items-start'>
              
            
                <div className='border-[2px] w-full px-3 sm:px-4 md:px-5 lg:px-[54px] py-6 sm:py-8 md:py-[45px] lg:w-[555px] h-auto rounded-xl border-solid border-[#E5E5E5]'>
                <div className='flex flex-col gap-3'>
                <div className='flex justify-center items-center '>
        <img className='rounded-full  bg-red-300 shadow-lg w-[10%] h-[10%]' src={ZiriProfile} height={50} width={50} alt="ziri" />
        </div>  
                <div>
                    <p>
                    Meet Ziri, your intelligent PayeeLord companion on WhatsApp! Experience lightning-fast digital transactions without ever leaving your chat. Whether you need to purchase airtime, activate data bundles, settle utility payments, generate recharge PINs, or manage your digital wallet - Ziri handles it all with unprecedented ease and efficiency. Your personal financial assistant is just a message away!
                    </p>
                </div>
              </div>
                    
                    <div className='flex items-center justify-center w-full gap-2'>
                        <div className='items-start flex-col py-3 sm:py-4 px-2 sm:px-3 bg-[#FFF3ED] border-l-[10px] rounded-tl-lg rounded-tr-sm rounded-br-sm rounded-bl-lg border-[#FF7D3B]'>
                            <p className="text-black text-xs sm:text-sm pb-2">Chat with Ziri to carry out seemless transactions without living Whatsapp.</p>
                            <div className="bg-green-500 max-w-[150px] flex items-center justify-center rounded-lg">
                                <a href='https://wa.me/message/2IUM62UNLW34N1' target='_blank' className='text-sm sm:text-base font-bold py-1.5 sm:py-2 px-2 text-white rounded-md'>CHAT WITH ZIRI</a>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className='flex justify-between gap-3 sm:gap-4 md:gap-5 mt-6'>
                        <div className='flex flex-col gap-3 sm:gap-4 w-full'>
                            <div className="bg-green-100 shadow-sm py-3 pl-2 rounded-md">
                                <p className='font-bold text-xs sm:text-sm'>WhatsApp Number</p>
                            </div>
                            {/* <label htmlFor="Ziri" className="text-xs sm:text-sm">WhatsApp Number</label> */}
                            <input 
                                type="text" 
                                required 
                                name='whatsappNumber'
                                value={whatsappNumber || userWhatsappNumber || ''}
                                onChange={(e) => setWhatsappNumber(e.target.value)}
                                className='pl-2 w-full border-[1px] border-solid rounded-lg h-8  min-h-10 placeholder:text-gray-500 text-sm' 
                            />
                            <button 
                                type="submit"
                                disabled={loading}
                                className='w-auto h-[38px] sm:h-[46px] px-[15px] sm:px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[10px] sm:text-[15px] xl:text-[16px] ease-in'
                            >
                                {loading ? 'UPDATING...' : 'UPDATE WHATSAPP NUMBER'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Ziri;
