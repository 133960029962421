import { Helmet } from 'react-helmet-async'
import { ContactForm, HeaderDescription } from '../components'

const ContactUs = () => {
  return (
    <>
    <Helmet>
      <meta name="payeelordapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="description" content="Contact payeelord - Get support for Airtime, Data, Cable TV subscriptions, Electricity payments and other digital services. Reach out to our customer service team for assistance." />
      <link rel='canonical' href='/contact'/>
      <meta itemprop="name" content="Contact payeelord - Customer Support for Digital Services and Payments" />
      <meta itemprop="description" content="Need help with MTN, Glo, Airtel, 9mobile services? Contact payeelord's support team for assistance with data bundles, airtime, DSTV, GOTV, electricity payments and more." />
      <meta itemprop="image" content="https://payeelord.com/images/hero images/hero bg.png" />
      
      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Contact payeelord - Customer Support for Digital Services and Payments" />
      <meta name="twitter:description" content="Get in touch with payeelord's support team for help with airtime, data bundles, bill payments and other digital services." />
      <meta name="twitter:image:src" content="https://payeelord.com/images/hero bg.png" />
      
      {/* Open Graph data */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content="Contact payeelord - Customer Support for Digital Services and Payments" />
      <meta property="og:image" content="https://payeelord.com/images/hero bg.png" />
      <meta property="og:description" content="Get in touch with payeelord's support team for help with airtime, data bundles, bill payments and other digital services." />
      <meta property="og:site_name" content="payeelord" />
      <meta property="og:url" content="https://payeelord.com/contact" />
      <meta property="og:type" content="website" />
    </Helmet>
   
    <div className='w-full h-auto md:mt-[100px] '>
{/* <HeaderStroke text='Contact Us' /> */}

<HeaderDescription title="Let's Hear From You"  text="We're here to help! Whether you have questions, need assistance, or want to share feedback, feel free to reach out to our friendly team by kindly filling out the form below."/>


  <ContactForm/>






    </div>

    </>
  )
}

export default ContactUs