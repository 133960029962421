import React from 'react'
import { left_line, right_line } from '../assets'

const HeaderDescription = ({title, text}) => {
  return (
    <div className='w-full h-auto px-[24px]  md:px-[100px] xl:px-[358px] '>
        



        <div className='w-full  h-auto flex items-center justify-center gap-5 pt-12 md:pt-[60px] '>
        <img src={left_line} className='w-[60px] md:w-[150px] lg:w-full ' alt="Left Line" />
        <h2 className='text-[18px] md:text-[36px] font-[700] leading-[24px] md:leading-[49px] whitespace-nowrap'>{title}</h2>
        <img src={right_line} className='w-[60px] md:w-[150px] lg:w-full ' alt="Right Line" />
      </div>
      <div className='w-full px-[20px]  pt-2'>
        <p className='text-[16px] text-center leading-[21px]'>
       {text}
        </p>
      </div>  
    </div>
  )
}

export default HeaderDescription