import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  FaRegEye } from 'react-icons/fa';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import formatDate from './FormatData';
import getTime from './GetTime';
import { FiRefreshCcw } from 'react-icons/fi';
import { airtime_cash_txt_icon, no_transaction_icon } from '../../assets';
import { getAirtimeTrans, getDataTrans, setPage, setLimit, setSearch, setFilter, getTransReceipt } from '../../controller/historySlice';
import { CiReceipt } from 'react-icons/ci';

const Transactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { airtimeTransactions, dataTransactions, loadingHistory, page, limit, totalPages, search, filter } = useSelector((state) => state.history);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    dispatch(getAirtimeTrans({ page, limit, search, filter }));
    dispatch(getDataTrans({ page, limit, search, filter }));
  }, [dispatch, page, limit, search, filter]);

  useEffect(() => {
  }, [airtimeTransactions, dataTransactions]);

  const handlePageChange = (event, value) => {
    dispatch(setPage(value));
  };

  const handleLimitChange = (event) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
  };

  const handleSearchChange = (event) => {
    dispatch(setSearch(event.target.value));
  };

  const handleFilterChange = (event) => {
    dispatch(setFilter(event.target.value));
  };

  const handleViewReceipt = async (id) => {
    const resultAction = await dispatch(getTransReceipt({ id }));
    if (getTransReceipt.fulfilled.match(resultAction)) {

      navigate('/dashboard/reciept');
    } else {
      console.error('Failed to fetch receipt:', resultAction.payload);
    }
  };

  return (
    <div className='h-auto rounded-2xl px-3 border-2 border-solid border-[#0908084D] mt-5'>
      <div className='w-full flex items-center justify-between py-3 px-5'>
        <p className='font-semibold'>Transactions</p>
        <div className='cursor-pointer'>
          <button 
            className='text-[#F2B08F] text-xl font-bold italic' 
            onClick={() => {
              setRefreshing(true);
              dispatch(getAirtimeTrans({ page, limit, search, filter }));
              dispatch(getDataTrans({ page, limit, search, filter }));
              setTimeout(() => {
                setRefreshing(false);
              }, 1000);
            }}
          >
            <FiRefreshCcw />
          </button>
        </div>
      </div>
      <div className='flex justify-between items-center mb-4'>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
        <select value={limit} onChange={handleLimitChange} className='form-select'>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </select>
      </div>
      <div className='flex justify-between items-center mb-4'>
        <input  
          type='text' 
          value={search} 
          onChange={handleSearchChange} 
          placeholder='Search transactions...' 
          className='form-input pl-2 rounded-md'
        />
        <select value={filter} onChange={handleFilterChange} className='form-select'>
          <option value=''>All</option>
          <option value='successful'>Successful</option>
          <option value='pending'>Pending</option>
          <option value='refunded'>Refund</option>
          <option value='failed'>Failed</option>
        </select>
      </div>
      {loadingHistory ? (
        <div className='flex justify-center items-center h-64'>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="transactions table">
            <TableHead>
              <TableRow>
                <TableCell>Icon</TableCell>
                <TableCell>Transaction Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Transaction Number</TableCell>
                <TableCell>Transaction Channel</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTransactions?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img src={airtime_cash_txt_icon} alt='icon' />
                  </TableCell>
                  <TableCell>
                    <div className='flex flex-col'>
                      <p className='text-xs'>{item?.dataName}</p>
                      {/* <span className={`text-xs ${item?.transNature === 'Debit' ? 'text-red-600' : (item?.transNature === 'Reversed' ? 'text-yellow-600' : 'text-green-600')}`}>{item?.transNature}</span> */}
                      {
            item?.token ? (
              <p className='font-semibold text-xs '>{item.meter_number}</p>
            ) : (
              <p className='font-semibold text-xs'>{item.mobile_number}</p>
            )
          }
                      <span className={`text-xs ${item?.transNature === 'Debit' ? 'text-red-600' : (item?.transNature === 'Reversed' ? 'text-yellow-600' : 'text-green-600')}`}>{item?.transNature}</span>
                    </div>
                  </TableCell>
                  <TableCell>{item?.amount}</TableCell>
                  <TableCell>
                    <div className='flex flex-col'>
                      <p className='text-sm'>{formatDate(item?.date)}</p>
                      <span className='text-xs text-gray-500'>{getTime(item?.date)}</span>
                    </div>
                  </TableCell>
                  <TableCell>{item?.ident}</TableCell>
                  <TableCell>{item?.trans_channel?.toUpperCase()}</TableCell>

                  <TableCell className={`${item?.status === 'successful' ? 'text-green-600' : item.status === 'pending' ? 'text-yellow-600' : 'text-red-600'}`}>{item.status}</TableCell>
                  <TableCell>
                    <div className='flex items-center gap-2'>
                      <button onClick={() => handleViewReceipt(item.id)}>
                        <FaRegEye />
                      </button>
                      <CiReceipt />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {(airtimeTransactions?.length === 0 && dataTransactions?.length === 0) && (
        <div className='flex items-center justify-center flex-col mt-[100px]'>
          <img src={no_transaction_icon} alt="No transactions" />
          <h1>No Transactions Yet!!!</h1>
        </div>
      )}
    </div>
  );
};

export default Transactions;
