import { useState } from "react";
import VirtualAccoutPopup from "./VirtualAccoutPopup";
import { useDispatch, useSelector } from "react-redux";
import { clearAirtelCorporateGiftingError, clearAirtimeCashError, clearAirtimeError, clearDataError, clearGiftingError, clearGloCorporateCashError } from "../../controller/buySlice";
import { clearBulksmsError } from "../../controller/bulksmsSlice";
import { clearCableError } from "../../controller/buyCableSlice";
import { clearBillError } from "../../controller/buyElectricSlice";
import { getUser } from "../../controller/authSlice";

const GenerateVirtualAccount = () => {
  const [virtualPopup, setVirtualPopup] = useState(false);
  const [monnify, setMonnify] = useState('');
  const [payvessel, setPayvessel] = useState('');
  const { user } = useSelector((state) => state.auth);

  const firstBankDetails = user?.payvesselDetails || [];
  const secondBankDetails = user?.monnifyDetails || [];
  const thirdBankDetails = user?.whoGoPayDetails || [];
  const forthBankDetails = user?.whoGoPayPalmpayDetails || [];


const dispatch =useDispatch();
  const handleClose = () => {
    setVirtualPopup(false);

    dispatch(clearAirtimeError());
    dispatch(clearDataError());
    dispatch(clearBulksmsError());
    dispatch(clearCableError());
    dispatch(clearBillError());
    dispatch(clearAirtimeCashError());
    dispatch(getUser());

  };

  const handlePopup = () => {
    setVirtualPopup(true);
  };

  const handleMonnify = () => {
    setMonnify('monnify');
  };

  const handlePayvessel = () => {
    setMonnify('payvesel');
  };

  const handleWhoGoPay = () => {
    setMonnify('whoGoPay');
  };
  const handleWhoGoPayPalmpay = () => {
    setMonnify('whoGoPayPalmpay');
  };
  return (
    <div className="flex gap-5 flex-col md:flex-row items-center mt-10 w-full">
      {secondBankDetails.length === 0 && (
        <div className="border-2 border-solid border-[#E5E5E5] w-full lg:w-[315px] md:w-[230px] h-[200px] py-[27px] px-[18px] rounded-2xl shadow-md flex flex-col gap-4">
          <div className="flex w-full justify-center gap-1 flex-col items-center">
            <p className="font-semibold text-base">Generate Automated Virtual Account using either your BVN or NIN</p>
            <span className="text-gray-500 text-xs italic">
              Funds sent to this account appear in your wallet almost immediately (charges at 1.613%)
            </span>
          </div>
          <button
            onClick={() => {
              handlePopup();
              handleMonnify();
            }}
            className="w-auto h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2 border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center ease-in"
          >
            Generate Now
          </button>
        </div>
      )}
      
      {forthBankDetails.length === 0 && (
        <div className="border-2 border-solid border-[#E5E5E5] w-full lg:w-[315px] md:max-w-[350px] md:min-w-[230px] h-[230px] min-h-min-[250px] py-[27px] px-[18px] rounded-2xl shadow-md flex flex-col gap-4">
          <div className="flex w-full justify-center gap-1 flex-col items-center">
            <p className="font-semibold text-base">Generate <span className="text-red-800">Palmpay</span> Automated Virtual Account  using phone number <span className="text-red-600 italic">Recommended</span></p>
            <span className="text-gray-500 text-xs italic">
              Funds sent to this (Palmpay) account appear in your wallet almost immediately (charges at 1.6%)
            </span>
          </div>
          <button
            onClick={() => {
              handlePopup();
              handleWhoGoPayPalmpay();
            }}
            className="w-auto h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2 border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center ease-in"
          >
            Generate Now
          </button>
        </div>
      )}
      {thirdBankDetails.length === 0 && (
        <div className="border-2 border-solid border-[#E5E5E5] w-full lg:w-[315px] md:max-w-[350px] md:min-w-[230px] h-[200px] py-[27px] px-[18px] rounded-2xl shadow-md flex flex-col gap-4">
          <div className="flex w-full justify-center gap-1 flex-col items-center">
            <p className="font-semibold text-base">Generate <span className="text-red-800">Bankly</span> Automated Virtual Account  using phone number</p>
            <span className="text-gray-500 text-xs italic">
              Funds sent to this account appear in your wallet almost immediately (charges at 1.6%)
            </span>
          </div>
          <button
            onClick={() => {
              handlePopup();
              handleWhoGoPay();
            }}
            className="w-auto h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2 border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center ease-in"
          >
            Generate Now
          </button>
        </div>
      )}
      {firstBankDetails.length === 0 && (
        <div className="border-2 border-solid border-[#E5E5E5] w-full lg:w-[315px] md:w-[230px] h-[200px] py-[27px] px-[18px] rounded-2xl shadow-md flex flex-col gap-4">
          <div className="flex w-full justify-center gap-1 flex-col items-center">
            <p className="font-semibold text-base">Generate Automated Virtual Account  using either your BVN or NIN</p>
            <span className="text-gray-500 text-xs italic">
              Funds sent to this account appear in your wallet almost immediately (charges at ₦35)
            </span>
          </div>
          <button
            onClick={() => {
              handlePopup();
              handlePayvessel();
            }}
            className="w-auto h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2 border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center ease-in"
          >
            Generate Now
          </button>
        </div>
      )}

      <VirtualAccoutPopup isVisible={virtualPopup} onClose={handleClose} source={monnify} />
    </div>
  );
};

export default GenerateVirtualAccount;
