import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "./axiosClient";

// Async thunk for fetching MTN data plan



export const allData = createAsyncThunk(
  'buyData/allData',
  async ({ dataId, mobileNumber, networkId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/all-web-data',
        { dataId, mobileNumber, networkId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data.message;
    } catch (error) {
      return rejectWithValue(
        // error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
        error.response?.data?.message || error.response?.data?.error || 'Something went wrong'
      );
    }
  }
);



// Async thunk for buying airtime
export const buyAirtime = createAsyncThunk(
  'Airtime/buyAirtime',
  async (dataPayload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/buy/airtime',
        dataPayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data.message;
    } catch (error) {
      return rejectWithValue(
        error.response.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);
export const airtimeConversion = createAsyncThunk(
  'convertAirtime/convertAirtime',
  async (dataPayload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const response = await axiosClient.post(
        '/v1/airtime/conversion',
        dataPayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data.message;
    } catch (error) {
      return rejectWithValue(
        error.response.data?.message || error.message || 'Something went wrong'
      );
    }
  }
);

// Data plan slice for managing airtime state
const buyAirtimeSlice = createSlice({
  name: 'Airtime',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyAirtime.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyAirtime.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(buyAirtime.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});

// Data plan slice for managing data state
const buyDataSlice = createSlice({
  name: 'buyData',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      
      .addCase(allData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(allData.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(allData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
      
  }
});


const airtimeConversionSlice = createSlice({
  name: 'convertAirtime',
  initialState: {
    loading: false,
    error: null,
    
    message: null
  },
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(airtimeConversion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(airtimeConversion.fulfilled, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        
      })
      .addCase(airtimeConversion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        
      });
  }
});



export const { clearError: clearAirtimeCashError, clearMessage: clearAirtimeCashMessage } = buyAirtimeSlice.actions;
export const { clearError: clearAirtimeError, clearMessage: clearAirtimeMessage } = buyAirtimeSlice.actions;
export const { clearError: clearDataError, clearMessage: clearDataMessage } = buyDataSlice.actions;


export const buyDataReducer = buyDataSlice.reducer;
export const buyAirtimeReducer = buyAirtimeSlice.reducer;
export const airtimeConversionReducer = airtimeConversionSlice.reducer;
