import { Feature,  Pricing} from '../components'
import { servicebg } from '../assets'
import { Helmet } from 'react-helmet-async'


const OurServices = () => {
  return (
    <>
<Helmet>
      <title>PayeeLord - Buy Airtime, Data, Pay Bills & Digital Services in Nigeria</title>
      <meta name="description" content="PayeeLord is a Nigeria-based telecommunications company offering cost-effective digital solutions. Purchase airtime, data bundles, pay for Cable TV, electricity bills, and more at the best prices." />
      <meta name="keywords" content="airtime recharge, data bundles, MTN, Glo, Airtel, 9mobile, DSTV payment, GOTV payment, electricity bills, digital services, Nigeria" />
      <meta name="application-name" content="PayeeLord" />
      <meta name="theme-color" content="#ffffff" />
      
      <meta itemProp="name" content="PayeeLord - Buy Airtime, Data, Cable TV Subscriptions & Bill Payments" />
      <meta itemProp="description" content="Buy cheap and reliable MTN, Glo, Airtel, 9mobile data bundles and airtime. Pay for DSTV, GOTV, electricity bills, and other digital services at the best rates." />
      <meta itemProp="image" content="https://payeelord.com/images/hero-images/hero-bg.png" />
      
      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="PayeeLord - Digital Services & Bill Payments in Nigeria" />
      <meta name="twitter:description" content="Purchase airtime, data bundles, and make payments for DSTV, GOTV, electricity bills at competitive prices." />
      <meta name="twitter:image" content="https://payeelord.com/images/hero-bg.png" />
      
      {/* Open Graph meta tags */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content="PayeeLord - Complete Digital Payment Solutions in Nigeria" />
      <meta property="og:description" content="Your one-stop platform for airtime, data bundles, TV subscriptions, and bill payments. Fast, secure, and reliable digital services." />
      <meta property="og:image" content="https://payeelord.com/images/hero-bg.png" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="PayeeLord" />
      <meta property="og:url" content="https://payeelord.com/services" />
      
      {/* Additional SEO optimization */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="PayeeLord" />
      <link rel="canonical" href="https://payeelord.com/services" />
</Helmet>
    <div className='mt-[100px]'>
     
      <Feature/>
      <Pricing/>

      <div className='w-full h-auto px-5 lg:px-[100px] mt-[40px]'>
        

<img className='w-full h-full' src={servicebg} alt='Service' />


      </div>
      {/* <Footer/> */}

    </div>
    </>
  )
}

export default OurServices