import { useEffect, useState } from 'react';
import { IoCopyOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import formatDate from './FormatData';
import getTime from './GetTime';
import { withdrawRefEarning } from '../../controller/authSlice';
import WidthrawPopup from './WidthrawPopup';



const Referral = () => {
    const { user } = useSelector((state) => state.auth);

    const [copySuccess, setCopySuccess] = useState('');
    const [openPopup, setOpenPopup] = useState(false);

    const copyToClipboard = () => {
        const referralLink = user?.username 
        ? `https://payeelord.com/register?ref=${user.username}` 
        : '';
           navigator.clipboard.writeText(referralLink).then(() => {
            setCopySuccess('Link copied to clipboard!');
            setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
        }).catch((err) => {
            setCopySuccess('Failed to copy link.');
        });
    };

    const handleClose = () => setOpenPopup(false);
    const handleOpenPopup = (e) => {
        e.preventDefault();
        setOpenPopup(true);
    }

    

    const referrals = user?.referrals

    const totalRef = referrals.length;

   

    return (
        <div className='lg:w-[480px] h-auto border-2 border-solid border-[#e5e5e5] px-5 rounded-lg py-[35px]'>
            <div className="w-full pb-4 flex flex-col items-center justify-center">
                <h1 className="font-bold">Referral Link</h1>
                {/* <p className='bg-[#FF7D3B] py-1 px-3 text-white rounded-md mb-1'>http://localhost:3000/register?ref={user?.username}</p> */}
                <p className='bg-[#FF7D3B] py-1 px-3 text-white rounded-md mb-1'>https://payeelord.com/register?ref={user?.username}</p>
                <button onClick={copyToClipboard} aria-label="Copy referral link">
                    <IoCopyOutline /> 
                </button>
                {copySuccess && <p className="text-green-500 mt-2">{copySuccess}</p>}

                <div className='flex justify-start w-full'>Total Referrals:  <span className='font-bold'> {totalRef}</span></div>
                <div className="w-full flex items-center justify-between mt-4">
                    <p>Total Earning: {user?.refBalance}</p>
                    <p className='cursor-pointer font-semibold' onClick={handleOpenPopup}>Withdraw Earning</p>
                </div>
            </div>

            <div className="flex flex-col gap-3 h-[500px] overflow-y-scroll">
                {referrals.map((item, index) => (
                    <div key={index} className="flex items-center rounded-md h-12 py-3 justify-between p-5 border-2 border-solid border-[#e5e5e5]">
                        <h4 className="text-base">{item?.username}</h4>
                        <div className="text-[#9fa3a9]">
                            <p className="text-base">{formatDate(item.dateJoined)}</p>
                            <p className="text-xs">{getTime(item.dateJoined)}</p>
                        </div>
                    </div>
                ))}
            </div>
            <WidthrawPopup isVisible={openPopup} onClose={handleClose} headMessage="Widthraw you referral earning to wallet, note that minimum withdraw is #100." />
        </div>
    );
}

export default Referral;
