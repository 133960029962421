import { useEffect, useState } from 'react'
import {  help_support_icon,  mobile_dropdown_icon, payment_icon, playstore, quick_icon, total_earning_icon, transact_icon,  wallet_balance_icon } from '../../assets'
import { IoIosArrowDown } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { MdRefresh } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../controller/authSlice'


const TopMenu = ({userBalance, totalFunding, refBalance}) => {
  const { isAuthenticated } = useSelector((state) => state.auth); // Adjust based on your state structure

  const dispatch = useDispatch();

    const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () =>{
    setShowMenu(!showMenu);
  }

  const handleRefresh = ()=>{
      dispatch(getUser());
  }

  useEffect(() => {
    if (isAuthenticated) {
      handleRefresh(); // Initial fetch

      const interval = setInterval(() => {
        handleRefresh();
      }, 30000); // 30 seconds interval

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isAuthenticated, dispatch]);
  return (
    <div>

{/* for big screen */}
<div className='w-full h-auto mt-[130px] px-5 xl:px-[44px] py-2 gap-[2px] bg-[#FFF3ED] hidden lg:flex items-center justify-between '>

<div className='flex gap-1 items-center border-[1px] border-solid border-[#FF7D3B] py-1 px-2 rounded-2xl'>
<img src={wallet_balance_icon} alt='Wallet' className='w-[24px]' />
  <div className=''>
<div className='flex items-center gap-1'>
<h2 className='text-[12px]'>Wallet Balance</h2><span onClick={handleRefresh} className='text-[#FF7D3B] cursor-pointer text-base font-semibold'><MdRefresh /></span>
  
  </div>   
    <span className='font-bold text-[14px] xl:text-[18px]'>
  N{userBalance || '0.00'}
</span>
  </div>
</div>

{/* after first item  */}


{/* <div className='flex gap-2 items-center'> */}
{/* <img src={payment_icon} alt="" />
<h2 className='text-[12px]'>Make <br className=' xl:hidden'/> Payment</h2> */}
 <Link to='/dashboard/payments' className='flex gap-2 items-center'><img src={payment_icon} alt="Payment Icon" />
 <h1 className='text-[12px] font-bold'>Make Payment</h1></Link>

{/* </div> */}

             <div className='h-[45px] w-[2px] bg-[#0908084D]'/>



{/* after the second item  */}
<div className='flex gap-2 items-center'>
<img src={total_earning_icon} alt="Total Earning" />

<h2 className='text-[12px] font-bold text-nowrap'>Total <br className='lg:hidden'/>Funding: <span className='text-[16px] font-bold'>N{totalFunding || 0.00} </span></h2>

</div>
<div className='h-[45px] w-[2px] bg-[#0908084D]'/>

<div className='flex gap-2 items-center'>
<img src={total_earning_icon} alt="Total Earning" />
 <h2 className='text-[12px] font-bold text-nowrap'>Referral  <br className='lg:hidden'/>Earning: <span className='text-[16px] font-bold'>N{refBalance || 0.00}</span></h2>
</div>

   {/* <div className='h-[45px] w-[2px] bg-[#0908084D]'/> */}

{/* after the third earning  */}


{/* <Link to='/dashboard/transactions' className='flex items-center gap-1'>
            
<img src={transact_icon} alt="" />
 <h2 className='text-[12px] md:break-all'>Transactions</h2>
 </Link> */}


        <div className='h-[45px] w-[2px] bg-[#0908084D]'/>

<div className='flex gap-2 items-center'>
<img src={quick_icon} alt="Quick Icon" />
 <h2 className='text-[12px] font-bold'>Shortcuts</h2>
<IoIosArrowDown />
</div>

             {/* <div className='h-[45px] w-[2px] bg-[#0908084D]'/> */}




{/*                <div className='h-[45px] w-[2px] bg-[#0908084D]'/>

*/}

</div>


{/* for small screens  */}

{/* <div className='w-full h-auto  px-[44px] bg-[#FFF3ED] md:hidden items-center justify-between '> */}


<div className='px-[20px] mt-[130px] lg:hidden'>

<div className='flex  justify-between pb-4 '>
        <p>Dashboard</p>
        <button onClick={handleMenu}>
          <img src={mobile_dropdown_icon} alt="Dropdown Icon" />
        </button>
      </div>

      {showMenu && (
        <div className='fixed inset-0 bg-black bg-opacity-50 z-20' onClick={handleMenu}></div>
      )}

      <div className={`${showMenu ? 'block' : 'hidden'} bg-[#FFF3ED] mb-2 px-[20px] py-4 z-20 rounded-lg relative`}>
        <div className='flex items-center justify-between'>
        {/* <div className='flex items-center gap-3 pr-[22px] h-[28px] border-r-[2px] border-solid'> */}

            <Link to='/dashboard/payments' className='flex items-center gap-1  pr-[22px] h-[28px] border-r-[2px] border-solid'><img src={payment_icon} alt="Payment Icon" />
            <h1 className='text-[12px] font-bold'>Make Payment</h1></Link>
          {/* </div> */}

          <div className='flex items-center gap-3'>
            <Link to='/dashboard/transactions'>
            <img src={transact_icon} alt="Transactions Icon" />
            <h1 className='text-[12px] font-bold'>Transactions</h1> </Link>
          </div>
        </div>
        <div className='flex items-center justify-between pt-4'>
          <div className='flex items-center gap-3 pr-10  h-[28px] border-r-[2px] border-solid'>
            <img src={quick_icon} alt="Quick Tools Icon" />
            <h1 className='text-[12px] font-bold'>Shortcut</h1>
<IoIosArrowDown />

          </div>
          <div className='flex items-center gap-3'>
            <img src={help_support_icon} alt="Help Support Icon" />
            <h1 className='text-[12px] font-bold'>Help/Support</h1>
          </div>
        </div>
      </div>


  
<div className='flex items-center  justify-between md:hidden  gap-4 rounded-lg  h-[80px] px-5 bg-[#FFF3ED] '>
  
  {/* <div className='flex flex-col items-start   '> */}
          <div className='flex  items-center gap-3'>
  
  
  <img src={wallet_balance_icon} alt='Wallet'/>
  <div>
<div className='flex items-center gap-1'>
<h1 className='text-nowrap text-[14px]'>Wallet Balance</h1> <span onClick={handleRefresh} className='text-[#FF7D3B] cursor-pointer text-base font-semibold'><MdRefresh /></span>
  
  </div> 
      <h1 className='text-[14px] font-bold   border-solid text-[#090808]'>N{userBalance || 0.00}</h1>


   
  
  
  </div>
  </div>                
          <div>
          <div className='flex  items-center gap-1'>
  
  
  <img src={total_earning_icon} alt='Total Earning' />
  <h1 className='text-nowrap text-[14px]'>Referral Earnings:</h1>
  
  
  </div>                
  <div>
  

  <h1 className='text-[14px] font-bold pl-5  border-solid text-[#090808]'>N{refBalance  || 0.00}</h1>

   
          </div>
  </div>
  
      
   
  
  
  
       
       
  
  </div>
</div>



<div className='flex items-center px-[24px]  justify-center md:justify-between xl:px-[74px] gap-4 rounded-lg lg:gap-28 mt-[24px]  h-[80px] bg-[#FFF3ED]'>
  <h1 className='text-[12px] lg:text-[20px]'>Refer and Earn !!! Join our Referral Programme and Earn.</h1>

  <Link to="/dashboard/settings">
  <button  className='w-auto h-[46px] px-4 lg:px-[25px] shadow-md rounded-[10px] text-[14px] text-nowrap   bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center ease-in'>
  Join Now For Free</button>
  </Link>


</div>

<div className='flex flex-col items-center justify-center md:flex-row gap-2'>
<div className='flex flex-col items-center justify-center gap-1 pt-3'>
  <p className='font-semibold italic text-base'>Get our App on Playstore</p>
<a  className='cursor-pointer' href='https://play.google.com/store/apps/details?id=com.abiodun.postscholar' target='_blank'><img src={playstore} alt='Playstore'/></a>
</div>
<div className='flex flex-col items-center justify-center gap-1 pt-3'>
  <p className='font-semibold italic text-base' title='Intelligent PayeeLord companion on WhatsApp'>Let Ziri help you on Whatsapp.</p>
  <Link to='/dashboard/whatsapp'  className='py-2 px-4 rounded-md bg-[#FF7D3B] shadow-md text-white text-sm italic'>Click Here to Update Your Whatsapp Number</Link>

</div>
</div>

    </div>
  )
}

export default TopMenu