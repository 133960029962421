import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import MainDash from '../../components/dashboards/MainDash';
import { getUser } from '../../controller/authSlice';
import { getSlideImages } from '../../controller/slideShowSlice';
import { getAirtimeTrans, getDataTrans } from '../../controller/historySlice';
import { getCablePlans, getDataPlans, } from '../../controller/planSlice'


const Dashboard = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [hideWalletBalance, setHideWalletBalance] = useState(false);
  const [pinpop, setPinpop] = useState(false);

  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);

  const {  imageLoading } = useSelector((state) => state.slideImage);

  // Consolidated effect for user and other data fetching
  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    } else {
      if (!loading && user?.pinStatus !== 'created') {
        setPinpop(true);
      }


      dispatch(getSlideImages());
    dispatch(getAirtimeTrans());
    dispatch(getDataTrans());
    dispatch(getDataPlans());
    dispatch(getCablePlans());
   
    }

  }, [dispatch, user, loading]);

  const handleHideBalance = () => setHideWalletBalance(prev => !prev);
  const closePinPopup = () => setPinpop(false);
  const togglePasswordVisibility = () => setPasswordVisible(prev => !prev);

  // if (loading) {
  //   return (
  //     <div className="text-center mt-80 mb-80 flex flex-col justify-center h-[90px] items-center">
  //              <div className="loader" />


  //     </div>
  //   );
  // }

  return (
    <>
      <MainDash
        passwordVisible={passwordVisible}
        hideWalletBalance={hideWalletBalance}
        pinpop={pinpop}
        imageloading={imageLoading}
        handleHideBalance={handleHideBalance}
        togglePasswordVisibility={togglePasswordVisibility}
        closePinPopup={closePinPopup}
      />
    </>
  )
}

export default Dashboard