import { banklyLogo, moniepoint_logo, palmpayLogo, psb_logo, sterling_logo, wema_logo } from "../../assets";
import { useSelector } from 'react-redux';
import GenerateVirtualAccount from '../../components/dashboards/GenerateVirtualAccount';


const AutomatedFunding = () => {
  const { user, loading } = useSelector((state) => state.auth);

  const firstBankDetails = user?.payvesselDetails || [];
  const secondBankDetails = user?.monnifyDetails || [];
  const thirdBankDetails = user?.whoGoPayDetails || [];
  const forthBankDetails = user?.whoGoPayPalmpayDetails || [];

  // Combine bank details into one array for easier mapping
  const allBankDetails = [...forthBankDetails, ...firstBankDetails, ...secondBankDetails, ...thirdBankDetails];

  // Determine the logo for each bank
  const getBankLogo = (bankCode) => {
    switch (bankCode) {
      case "035":
        return wema_logo;
      case "232":
        return sterling_logo;
      case "20946":
        return palmpayLogo;
      case "50515":
        return moniepoint_logo;
      case "120001":
        return psb_logo;
      case "090529":
        return banklyLogo;
      default:
        return ''; 
    }
  };

  return (
    <div className="mt-[80px] px-2 xl:px-[74px]">
      <div className="flex gap-3 flex-col items-start my-5">
        <h1 className="font-bold text-lg">Automated Funding</h1>
        <p className="text-red-600 font-semibold">No Minimum deposit.</p>
      </div>


 
    <GenerateVirtualAccount/>
              
               

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 mt-[50px]">
                {allBankDetails.map((item) => (
                  <div key={item.id} className="border-2 border-solid border-[#E5E5E5] py-[27px] px-[35px] rounded-2xl shadow-md flex flex-col gap-4">
                    <div className="flex items-center justify-start gap-6 mb-[36px]">
                      <img src={getBankLogo(item.bank)} alt={`${item.bankName} Logo`} className="w-[50px]" />
                      <p className="font-semibold">Make transfer to this account to fund your wallet</p>
                    </div>
            
                    <div className="flex flex-col gap-4">
                      <div className="flex gap-2">
                        <p>Account Number:</p>
                        <p className="text-base font-semibold">{item.accountNumber}</p>
                      </div>
                      <div className="flex gap-2">
                        <p>Account Name:</p>
                        <p className="text-base font-semibold">{item.accountName}</p>
                      </div>
                      <div className="flex gap-2">
                        <p>Bank Name:</p>
                        <p className="text-base font-semibold">{item.bankName}</p>
                      </div>
                      {
                        item.bank === '20946' && (
                          <div className="flex justify-center">
                            <p className="text-red-700 font-semibold italic">Highly Recommended</p>
                           
                          </div>
                        )
                      }
                      <div className="flex items-center justify-center bg-[#FFF3ED] py-[13px] px-[68px] rounded-lg">
                        <p>Charges: {item.bank === '120001' ? '#35' : item.bank === '090529' ? '1.6%' : item.bank === '20946' ? '#35':'1.613%'}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              
    </div>
  );
};

export default AutomatedFunding;
