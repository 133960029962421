import React, { useState } from 'react';
import { SuccessAlert} from '../components';
import {  logo } from '../assets';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axiosClient from '../controller/axiosClient';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ResetForgotPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Password and confirm password mismatch!');
      return;
    }


    axiosClient.post('/v1/reset-password', {
      password: password,
      email: localStorage.getItem('otpEmail')
    })
      .then((response) => {
        if (response.status === 200) {
          const message = response.data.message;
          toast.success(message);
          setShowPopup(true);
        } else {
          const message = response.data.message;
          toast.error(message);
        }
      })
      .catch((error) => {
        console.error(error.response);
        toast.error(error.response?.data?.message || 'An error occurred');
      });
  };

  return (

    <div className='px-8 md:px-40 lg:px-64  w-full bg-[#FFF3ED] pb-[1px]' style={{ minHeight: '100vh' }}>

    <Link to="/"  className='flex justify-center  items-center pt-12'><img src={logo} alt="Logo" /></Link>
    
        <div className='loginBg py-[60px] px-[30px] w-full lg:w-[360px]  bg-white   rounded-md  flex flex-col items-center justify-center'>
            


    {/* <div className='bg-[#FFF3ED] overflow-scroll min-h-[900px]' >

<div  className='flex justify-center  items-center pt-10'><img src={logo} alt="Logo" /></div>

    
        <div className='loginBg py-[60px] px-[30px] bg-white   rounded-md  flex flex-col items-center justify-center'>
             */}
               
                <form onSubmit={handleSubmit} className='w-full'>
                  <div className='w-full items-center flex flex-col justify-center'>
                    <div className='w-full flex flex-col gap-4 items-center justify-center pb-4'>
                      <h1 className='text-3xl font-bold'>Forgot Password</h1>
                      <p className='text-xs lg:text-[16px]'>Enter your new password and confirm it</p>
                    </div>
                    <div className='relative w-full'>
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        name='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Enter Password'
                        className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                        required
                      />
                      <div
                        onClick={togglePasswordVisibility}
                        className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                      >
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                    <div className='relative w-full mt-4'>
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder='Confirm Password'
                        className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
                        required
                      />
                      <div
                        onClick={togglePasswordVisibility}
                        className='absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600'
                      >
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col gap-5 items-center justify-center w-full'>
                    <div className='w-full flex flex-col items-center justify-center pt-6'>
                      <button
                        type="submit"
                        className='py-2 px-8 bg-[#FF7D3B] w-full rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
          
          
      <SuccessAlert
        message="You have successfully created a new password"
        isVisible={showPopup}
        onClose={closePopup}
        path='/login'
      />
    </div>
  );
}

export default ResetForgotPassword;
