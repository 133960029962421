import { useEffect } from 'react';
import { airtel_icon, glo_icon, mtn_icon, nine_mobile_icon } from '../../assets';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDataPlans, getDataTypeStatus } from '../../controller/planSlice';


const DataInstant = () => {
  const { planList, dataStatus } = useSelector((state) => state.dataPlan);
  const dispatch = useDispatch();

    
useEffect(() => {

    dispatch(getDataTypeStatus());
  
}, [dispatch, dataStatus]);

  useEffect(() => {

    if (!planList || planList.length === 0) {
      dispatch(getDataPlans());
    
    
    }
  }, [dispatch, planList]);

 
  return (
    <div className='py-[24px]'>
      <h1 className='lg:text-[20px] font-bold text-[16px] px-[27px] md:px-0 pb-5'>Top up your DATA Instantly</h1>
      <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-10 items-center justify-center'>

        {dataStatus?.filter(item => item.status === 'activate')
          .map(item => {
            // Customize the content for each type based on item.type
            return (
              <div key={item.id} className='border-2 border-solid w-full lg:w-[315px] md:w-[230px] h-[200px] border-[#E5E5E5] flex flex-col justify-between items-center gap-10 py-[29px] px-[18px] rounded-2xl'>
                <div className='flex flex-col justify-between w-full h-full items-start'>
                  <div className='flex gap-3 items-center'>
                    {/* Use correct icon based on type */}
                    <img src={getIconForType(item.type)} alt={`${item.type} Icon`} />
                    <p className='text-[13px] lg:text-[16px] font-bold'>{item.type} Data</p>
                  </div>
                  <Link to={`/dashboard/buy/${getLinkForType(item.type)}/data`} className='w-full'>
                    <button className='w-full h-[46px] px-[25px] text-sm font-semibold shadow-md rounded-[10px] border-2 border-[#FF7D3B] text-[#FF7D3B] cursor-pointer hover:bg-[#FF7D3B] hover:text-white transition-all duration-300 hover:scale-95 flex items-center justify-center ease-in'>
                      Buy
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}

      </div>
    </div>
  );
};

// Helper function to determine the correct icon for each data type
const getIconForType = (type) => {
  switch (type) {
    case 'SME':
    case 'SME2':
    case 'DATASHARE':
      return mtn_icon;
    case 'MTN CORPORATE GIFTING':
    case 'GIFTING':
    case 'AWOOF GIFTING':
      return mtn_icon;
    case 'AIRTEL GIFTING':
    case 'AIRTEL CORPORATE GIFTING':
      return airtel_icon;
    case 'GLO CORPORATE GIFTING':
    case 'GLO GIFTING':
      return glo_icon;
    case '9MOBILE CORPORATE GIFTING':
      return nine_mobile_icon;
    default:
      return mtn_icon; // default to MTN if not found
  }
};

// Helper function to determine the correct link for each data type
const getLinkForType = (type) => {
  switch (type) {
    case 'SME':
      return 'mtn/sme';
    case 'SME2':
      return 'mtn/sme2';
    case 'DATASHARE':
      return 'mtn/datashare';
    case 'MTN CORPORATE GIFTING':
      return 'mtn/corporate';
    case 'AWOOF GIFTING':
      return 'mtn/awoof-gifting';
    case 'GIFTING':
      return 'mtn/gifting';
    case 'AIRTEL GIFTING':
      return 'airtel/gifting';
    case 'AIRTEL CORPORATE GIFTING':
      return 'airtel/corporate';
    case 'GLO CORPORATE GIFTING':
      return 'glo/corporate';
    case 'GLO GIFTING':
      return 'glo/gifting';
    case '9MOBILE CORPORATE GIFTING':
      return '9mobile/corporate';
    default:
      return '';
  }
};

export default DataInstant;
