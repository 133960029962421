import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, clearBeneError, withdrawRefEarning } from '../../controller/authSlice';
import { clearAirtimeCashError, clearAirtimeError, clearDataError} from '../../controller/buySlice';
import { clearBulksmsError } from '../../controller/bulksmsSlice';
import { clearCableError } from '../../controller/buyCableSlice';
import { clearBillError } from '../../controller/buyElectricSlice';
import { toast } from 'react-toastify';

const WidthrawPopup = ({  headMessage, isVisible, onClose}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [amount, setAmount] = useState('');
  const { error, message, loading  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (message) {
        toast.success(message);
    }
}, [message]);
  
useEffect(() => {
  if (error) {
      toast.error(error);
  }
}, [error]);

  if (!isVisible) return null;
 

  
 

  const payload  = {
    amount: amount,
  }
  


    const handleWithdraw = async (e) => {
        e.preventDefault();
        dispatch(clearAirtimeError());
        dispatch(clearDataError());
    
    
        dispatch(clearBulksmsError());
        dispatch(clearCableError());
        dispatch(clearBillError());
        dispatch(clearAirtimeCashError());
  
        dispatch(clearBeneError());
    
        
        setErrors([]);
        try {
          const resultAction = await dispatch(withdrawRefEarning(payload));
          if (withdrawRefEarning.fulfilled.match(resultAction)) {
            // setToastMessage('Pin Created Successfully');
            // toast.success('Pin Created Successfully');
            onClose();
            setAmount('');
            dispatch(getUser());
    
          } else {
            setErrors([resultAction.payload.message || resultAction.error.message]);
          }
        } catch (error) {
          console.error(error)
          setErrors([error.message]);
        }
      }


  if (loading) {
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
  <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>

    <div className="loader" />


        
        </div>
        </div>
    );
}

  return (
    <div className='fixed inset-0 flex items-center justify-center xl:ml-[320px] xl:mt-[220px] xl:mr-[36px] bg-black bg-opacity-80 z-40'>
      <div className='bg-white px-[47px] pt-8 gap-4 rounded-xl w-full mx-4 md:w-[400px] h-[300px] flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className='font-semibold'>{headMessage}</p>
        </div>
        <div className='flex gap-2 w-full'>
          <input
            type="text"
            placeholder='Enter amount you want to withraw...'
            value={amount}
            maxLength="11"
            onChange={(e) => setAmount(e.target.value)}
            className='border-[2px] rounded-lg py-2 px-4 w-full ring-0 focus:ring-gray-300 border-solid text-black placeholder:text-black'
            required
          />
        </div>
        <div className='flex items-center justify-between w-full'>
          <button
            onClick={handleWithdraw}
            className='h-[46px] w-[120px] bg-[#FF7D3B] flex items-center justify-center font-bold rounded-xl text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300'
          >
            Proceed
          </button>
          <button
            className='h-[46px] w-[120px] rounded-xl text-[#FF7D3B] border-[1px] border-solid border-[#FF7D3B] shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300 font-bold'
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
        <p className='text-center text-red-600 text-[16px] leading-[21px] mt-5'>
          {error || errors[0]}
        </p>
      </div>
    </div>
  );
};

export default WidthrawPopup;
