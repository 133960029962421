import { useEffect, useRef } from 'react';
import { failure_icon } from '../assets';
import { useDispatch } from 'react-redux';
import {
  clearAirtimeCashError,
  clearAirtimeError,
  clearDataError,
} from '../controller/buySlice';
import { clearBulksmsError } from '../controller/bulksmsSlice';
import { clearBillError } from '../controller/buyElectricSlice';
import { clearCableError } from '../controller/buyCableSlice';
import {  getUser} from '../controller/authSlice';

const FailureAlert = ({ message, isVisible, onClose }) => {
  const dispatch = useDispatch();
  const boxRef = useRef();

  useEffect(() => {
    if (!isVisible) return;

    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, dispatch]);

  const handleClose = () => {
    dispatch(clearAirtimeError());
    dispatch(clearDataError());
    dispatch(clearBulksmsError());
    dispatch(clearCableError());
    dispatch(clearBillError());
    dispatch(clearAirtimeCashError());

    dispatch(getUser());


    if (onClose) onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40">
      <div
        ref={boxRef}
        className="bg-white p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center"
      >
        <div className="flex flex-col items-center">
          <p className="text-[#D80404] font-semibold py-[10px]">Failed</p>
          <p className="text-center text-[16px] leading-[21px]">
            {message}
          </p>
          <img className="w-[30px]" src={failure_icon} alt="Failure Icon" />
        </div>
        <button
          className="py-2 px-10 bg-[#FF7D3B] rounded-xl h-[50px] text-white shadow-md hover:opacity-90 transition-all hover:scale-95 duration-300"
          onClick={handleClose}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default FailureAlert;
